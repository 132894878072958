function AppCtrl(config) {
	this.appCtrlConfig = {aspSpecific:1};
	if (VRA.toType(config) == "object")
		$.extend(this.appCtrlConfig, config);
	/**
	 * Subset of VRA.callPhpMethod. JSON-only.
	 * 
	 * @param params
	 *            Hash containing one or more keys: cls, mthd, params, element,
	 *            callback, feedbackElement, completionMessage, checkMarkSize, webCache
	 *            OR
	 *            Serialization of above hash
	 */
	this.callPhp = function(params) {
		var phpFile = "/includes/functions/ajax_func_user.php";
		if(params.webCache)
		  phpFile += '?webCache=1';
		var p = params;
		if (VRA.toType (p.params) == "object")
			p.params = $.param(p.params);
		else if(p.params == undefined)
			p.params = "";
		p.params += "&json=1&aspSpecific="+this.appCtrlConfig.aspSpecific;
		if(this.singleton)
			p.params += "&id=skip";
		var phpParams = {
			cls : p.cls,
			mthd : p.mthd,
			vars : p.params
		};
		if (p.feedbackElement && VRA.circleLoader)
			VRA.circleLoader(p.feedbackElement);
		var timerName = 'callPhp-'+(p.cls ? p.cls+'-' : '')+p.mthd;
		VRA.timerStart(timerName);
		return $.ajax({
			type : "POST",
			url : phpFile,
			data : phpParams,
      dataType : "json",
			async: !p.synchronous,
			timeout: 30000,
			error: function(jqXHR,stat,err) {
			  VRA.sendToRaygun(new Error(err),{
			    method: 'callPhp ajax',
			    phpParams: phpParams,
			    jqXHR: jqXHR,
			    status: stat,
			    elapsedTime: VRA.timerElapsed(timerName)+' seconds'
			  });
			},
			success : function(data) {
				if (p.element && data && data.html)
					$(p.element).html(data.html);
				if (p.feedbackElement) {
					if(p.noCompletionCheckMark)
						$(p.feedbackElement).html("");
					else
						VRA.ajaxCompletionMessage(p.feedbackElement, p.completionMessage, p.checkMarkSize);
				}
				if (p.callback)
					p.callback.call(this, data);
				if(VRA.toType(VRA.notificationManager) == "object")
					VRA.notificationManager.process(data);
			}
		});
	};
	this.show = function() {
		$(this.containerID).removeClass("hidden").show();
	};
	this.hide = function() {
		$(this.containerID).hide();
	};
	this.getElements = function(selector) {
		return $(this.containerID).find(selector);
	};
	this.getForm = function() {
		return this.getElements("form,.form").addBack().filter("form,.form");
	};
  this.getFormElement = function(id) {
    id = this.getDotNotation2ElementID(id);
    return this.getForm().find("#"+id);
  };
	this.getFormVal = function(id) {
	  var el = this.getFormElement(id);
	  if(el)
	    return el.val();
	};
	this.replaceForm = function(html) {
	  this.getForm().replaceWith(html);
	};
	this.setFormVal = function(id,val) {
    var el = this.getFormElement(id);
	  if(el)
	    el.val(val);
	};
	this.setFormVals = function(data, dotNotPrefix) {
	  if(VRA.toType(data)=="object") {
	    var _this=this;
	    $.each(data, function(key,val){
	      if(dotNotPrefix)
	        key=dotNotPrefix+'.'+key;
	      _this.setFormVal(key,val);
	    });
	  }
	};
	this.getDotNotation2ElementID = function(str) {
    if(typeof(str)=='string' && str.indexOf('.')>-1) {
      var idParts = str.split('.');
      str = idParts.shift();
      $.each(idParts, function(idx,part){
        str+='\\['+part+'\\]';
      });
    }
    return str;
	};
	this.getFormInputs = function() {
	  return this.getForm().find(":input").not('.omit');
	};
	this.getFormParams = function() {
		return this.getFormInputs().serialize();
	};
	this.getFormParamsObj = function() {
	  return this.getFormInputs().serializeObject();
	};
	this.disable = function() {
		VRA.toggleUIEnabled(this.containerID,false);
	};
	this.enable = function() {
		VRA.toggleUIEnabled(this.containerID,true);
	};
	this.isEnabled = function() {
		return !VRA.withinDisabled(this.containerID);
	};
	this.disableForm = function() {
		VRA.toggleUIEnabled(this.getForm(),false);
	};
	this.enableForm = function() {
		VRA.toggleUIEnabled(this.getForm(),true);
	};
	this.formIsEnabled = function() {
		return !VRA.withinDisabled(this.getForm());
	};
	this.setInputEnabled = function(selector,how) {
		VRA.toggleInputsEnabled(this.getElements(selector),how);
	};
	this.addListener = function(obj) {
		if(!$.isArray(this.listeners))
			this.listeners = new Array();
		this.listeners.push(obj);
	};
	this.callListeners = function(func) {
		if($.isArray(this.listeners) && typeof(func)=="function")
			$.each(this.listeners,func);
	};
  this.setFormDataCookie = function(siteUsageDataName,expires) {
    siteUsageDataName = siteUsageDataName || this.phpClass;
    if(siteUsageDataName)
      VRA.SUCM.set(siteUsageDataName,this.getFormParamsObj(),expires);
  };
  this.getFormDataCookie = function(siteUsageDataName) {
    siteUsageDataName = siteUsageDataName || this.phpClass;
    if(siteUsageDataName)
      return VRA.SUCM.get(siteUsageDataName);
  };
}