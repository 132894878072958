if("ontouchstart" in window)
  document.documentElement.className += " touch";

VRA.counts = new Object();
VRA.incrCount = function(t) {
  if (VRA.counts[t])
    VRA.counts[t]++;
  else
    VRA.counts[t] = 1;
  return VRA.counts[t];
};
VRA.getCount = function(t) {
  return VRA.counts[t] || 0;
};
VRA.callPhpMethod = function(cls, mthd, params, element, callback, feedbackElement, completionMessage, checkMarkSize, datTyp) {
  var phpFile = "/includes/functions/ajax_func_user.php";
  var phpParams = {
    cls : cls,
    mthd : mthd,
    vars : params
  };
  if ((typeof params == 'string' && params.indexOf("json=1") > -1) || (VRA.toType(params)=="object" && params.json))
    datTyp = "json";
  if (feedbackElement != null) {
    VRA.circleLoader(feedbackElement);
    if (callback == null) {
      var callback = function() {
        VRA.ajaxCompletionMessage(feedbackElement, completionMessage, checkMarkSize);
      };
    }
  }
  if (datTyp == undefined)
    datTyp = "html";
  var ajaxSettings = {
    type : "POST",
    url : phpFile,
    data : phpParams,
    dataType : datTyp,
    error: function(xhr,stat,err) {
      VRA.logPageEvent('callPhpMethod error',{
        xhr:xhr,
        stat:stat,
        err:err
      });
    },
    success : callback
  };
  if (element == null) {
    return $.ajax(ajaxSettings);
  } else {
    if (!feedbackElement && params.indexOf("noLoadingFeedback=1") == -1)
      VRA.circleLoader(element);
    $(element).load(phpFile, phpParams, callback);
  }
};
VRA.ajaxCompletionMessage = function(element, message, checkMarkSize) {
  if (message == undefined) {
    message = "";
    if (checkMarkSize == undefined)
      checkMarkSize = 16;
  }
  if (checkMarkSize > 0)
    message += VRA.getCheckMark(checkMarkSize);
  $(element).hide().html(message).fadeIn("fast");
};
VRA.getCheckMark = function(size) {
  return '<div class="check_mark s' + size + '"></div>';
};
VRA.matchDatePicker = function(from, to, def, offset) {
  var toTime, fromDate = $('#' + from).datepicker("getDate");
  if (!VRA.isValidDate(fromDate))
    return false;
  if (VRA.toType(fromTime) == "object" && VRA.toType(fromTime.getTime) == "function")
    var fromTime = fromDate.getTime(), toDate = $('#' + to).datepicker("getDate");
  if (VRA.toType(toDate) == "object" && VRA.toType(toDate.getTime) == "function")
    toTime = toDate.getTime();
  if (!toTime || fromTime >= toTime) {
    var toDate = $('#' + from).datepicker("getDate");
    toDate.setDate(toDate.getDate() + offset);
    if (def)
      $('#' + to).datepicker("option", "defaultDate", toDate);
    else
      $('#' + to).datepicker("setDate", toDate);
  }
};
VRA.isValidDate = function(d) {
  if (Object.prototype.toString.call(d) !== "[object Date]")
    return false;
  return !isNaN(d.getTime());
};
VRA.withinDisabled = function(obj) {
  return $(obj).parents().addBack().hasClass('disabled');
};
VRA.enterify = function(el, fn) {
  $(el).keypress(function(e) {
    if (e.which == 10 || e.which == 13)
      fn();
  });
};
VRA.popUp = function(u, n, w, h, l, t, rs, s, bars) {
  var URL = u;
  var Name = n;
  var winparams = "width=" + w + ",height=" + h + ",left=" + l + ",top=" + t + ",resizable=" + rs + ",scrollbars=" + s + ",toolbars=" + bars + ",menubar=" + bars;
  return window.open(URL, Name, winparams);
};
VRA.showConfirmationDialog = function(options) {
  if (options.title !== undefined)
    $("#confirmation-dialog").parent().find(".ui-dialog-title").html(options.title);
  if (options.message !== undefined)
    $("#confirmation-dialog").html(options.message);
  var buttons = [ {
  text : options.yesButtonText || "Yes",
  click : function() {
    VRA.executeCallback(options.callbacks.yes);
    $(this).dialog("close");
  }
  }, {
  text : options.noButtonText || "No",
  click : function() {
    VRA.executeCallback(options.callbacks.no);
    $(this).dialog("close");
  }
  } ];
  var dialogOpts = {
    buttons : buttons
  };
  if (typeof options.jqDialogOpts)
    $.extend(dialogOpts, options.jqDialogOpts);
  $("#confirmation-dialog").dialog("option", dialogOpts);
  $("#confirmation-dialog").dialog("open");
};
VRA.showProgressTransition = function(message, withLogo) {
  var html = "";
  if (withLogo)
    html += "<div class=\"logo\"></div>";
  html += message + "<img src=\"/images/loader-bar-blue-squares.gif\" class=\"loader\"/>";
  VRA.showGenericAlert("Wait", html, true, {}, {
  width : 600,
  resizable : false,
  dialogClass : "processing_transition",
  closeOnEscape : false,
  draggable : false
  });
};
VRA.executeCallback = function(cb) {
  if (typeof cb == "string")
    eval(cb);
  else if (VRA.toType(cb)=="object")
    cb.func.apply(cb.targ, cb.args);
};
VRA.addClearDateButton = function(input) {
  setTimeout(function() {
    var buttonPane = $(input).datepicker("widget")
        .find(".ui-datepicker-buttonpane");
    var btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" type="button">Clear</button>');
    btn.unbind("click").bind("click", function() {
      $.datepicker._clearDate(input);
    });
    btn.appendTo(buttonPane);
  }, 1);
};
VRA.toggleCheckbox = function(id) {
  setCheckBoxChecked(id, !getCheckBoxChecked(id));
};
VRA.toggleMorified = function(link, params) {
  var p, targ, isDefaultTarg;
  var defaults = {
  selector : "div",
  label : {
  more : 'Read More <span class="glyphicon glyphicon-chevron-down"></span>',
  less : 'Show Less <span class="glyphicon glyphicon-chevron-up"></span>'
  }
  };
  if (VRA.toType(params) == "object")
    p = $.extend(true, defaults, params);
  else
    p = defaults;
  isDefaultTarg = p.selector == defaults.selector;
  if (p.selector.indexOf("#") == 0 || p.selector.indexOf(".") == 0)
    targ = $(p.selector);
  else
    targ = $(link).closest(p.selector).next();
  if (targ.is(":visible")) {
    $(link).html(p.label.more).removeClass("less").addClass("more");
    if (isDefaultTarg)
      targ.addClass("hid");
    else
      targ.hide();
  } else {
    if (p.noLess) {
      $(link).hide();
    } else {
      $(link).html(p.label.less).removeClass("more").addClass("less");
    }
    if (isDefaultTarg)
      targ.removeClass("hid");
    else
      targ.show();
  }
};
VRA.toggleUIEnabled = function(selector, how) {
  var inputs = $(selector).toggleClass("disabled", !how).find(":input").addBack()
      .filter(":input").not(":submit");
  VRA.toggleInputsEnabled(inputs, how);
};
VRA.toggleInputsEnabled = function(selector, how) {
  $(selector).prop("disabled", !how);
};
VRA.scrollToElement = function(selector, time, verticalOffset, complete) {
  time = typeof (time) != "undefined" ? time : 1000;
  verticalOffset = typeof verticalOffset != "undefined" ? verticalOffset : 0;
  var scrollPos = $(selector).offset().top + verticalOffset;
  if(time > 0) {
    $("html,body").animate({
      scrollTop : scrollPos
    }, time, 'swing', complete);
  } else {
    $("html,body").scrollTop(scrollPos);
    if(VRA.toType(complete)=='function')
      complete.call();
  }
};
VRA.addWindowListener = function(event, callback) {
  VRA.windowListeners = VRA.windowListeners || {};
  if(!VRA.windowListeners[event]) {
    VRA.windowListeners[event] = {
     callbacks: $.Callbacks(),
     heard: false
    };
    $(window).on(event,function(e) {
      VRA.windowListeners[e.type].heard = true;
    });
  }
  VRA.windowListeners[event].callbacks.add(callback);
  VRA.windowListenerInterval = VRA.windowListenerInterval || setInterval(function() {
    $.each(VRA.windowListeners,function(event){
      if (this.heard) {
        this.heard = false;
        this.callbacks.fire();
      }
    });
  }, 300);
  if(event == 'resize')
    VRA.addWindowListener('onorientationchange',callback);
};
VRA.isTouchDevice = function() {
  return ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
};
VRA.rgx = function(subj, pat) {
  var re = new RegExp(pat);
  return re.test(subj);
};