function PublicBookingForm() {
	PublicCustomerForm.call(this,"Booking");
	var _this = this;
	var init = this.init;
	
	this.init = function(options) {
	  if(options)
	    this.options = options;
	  if(!this.initialized) {
	    $(this.containerID)
	    .on("change","select",function(){
	      var formValidation = _this.validateForm();
	      if(formValidation.success) {
	        _this.update();
	        _this.populatePublicContactForm();
	      }
	    })
	    .on("click","button",function(){
	      _this.continueBooking(this);
	    });
	  }
		this.initDatePickers(this.options);
    this.populate();
		init.call(this);
		this.validateForm();
  };
  
  this.populate = function() {
    var dateParams = {},
    data = this.getFormDataCookie();
    if(VRA.toType(data)=="object") {
      dateParams = {
        "Check_In":data.Check_In_PublicBookingForm,
        "Check_Out":data.Check_Out_PublicBookingForm
      };
      this.setDates(dateParams);
      this.setFormVal('Guests',data.Guests || 0);
    } else {
      this.populateFromContactForm();
    }
  };
	
	this.datePickerSelected = function(dp,isoDate) {
	  this.updateCalendar();
    this.populatePublicContactForm();
    this.update();
	};
	
	this.updateCalendar = function() {
    if(VRA.publicBookingCalendar)
      VRA.publicBookingCalendar.show({slave:true,newDates:{Check_In:this.getDatePickerDateISO("Check_In"),Check_Out:this.getDatePickerDateISO("Check_Out")}});
	};
	
	this.update = function() {
	  if(!this.isEnabled())
	    return;
	  this.setStatusDisplay("CHECKING AVAILABILITY");
	  this.setFormDataCookie();
		var parms = this.getFormParams();
		this.disable();
		this.callPhp({
			cls:this.phpClass,
			mthd:"getOutput",
			params:parms,
			callback:function(data) {
				if(VRA.toType(data)=="object" && typeof data.html == "string") {
				  if(VRA.toType(data.conversionConfig)=="object")
				    _this.conversionConfig = data.conversionConfig;
				  _this.replaceForm(data.html);
          _this.enable();
          _this.init();
				}
			}
		});
	};

	this.setStatusDisplay = function(str) {
		this.getElements(".status").html(str);
	};
	
	this.hasValidDates = function() {
	  return this.getElements(".form-group.dates").hasClass('has-success');
	};
	
	this.validateForm = function() {
		var 
		hasValidDates = this.getElements(".form-group.dates").hasClass('has-success'),
		adults = parseInt(this.getFormVal("Num_Adults")),
		children = parseInt(this.getFormVal("Num_Children")),
		maxGuests = parseInt(this.getFormVal("maxGuests")),
		result = {success:hasValidDates && adults > 0 && adults+children <= maxGuests}
		if(!hasValidDates) {
		  result.error = "Please select valid dates.";
		} else if(!adults) {
      result.error = "Please select number of adults.";
		} else if(adults+children > maxGuests) {
		  result.error = "The number of adults and children exceeds the maximum of "+maxGuests+" guests.";
		} else if(!this.hasBookingDest()) {
		  result.error = 'Please click the "Contact Us" button below as this property is temporarily unavailable for direct booking.';
		}
		result.success = this.hasBookingDest() && hasValidDates && adults > 0 && adults+children <= maxGuests;
		if(!result.success)
		  this.getElements(".status").html('<span class="error"><strong>'+result.error+'</strong></span>');
		this.setSubmitButtonEnabled(result.success);
		return result;
	};
	
	this.setSubmitButtonEnabled = function(how) {
	  this.getElements("button").prop("disabled",!how);
	};
	
	this.populatePublicContactForm = function() {
		if(VRA.toType(VRA.publicContactForm)=="object") {
			VRA.matchDatePicker("Check_In_PublicBookingForm_Display","Check_In_PublicContactForm_Display",false,0);
			VRA.matchDatePicker("Check_Out_PublicBookingForm_Display","Check_Out_PublicContactForm_Display",false,0);
			var guests = parseInt(this.getFormVal("Num_Adults"),10)+parseInt(this.getFormVal("Num_Children"),10);
			VRA.publicContactForm.setFormVal("Guests",guests);
			VRA.publicContactForm.setSelectColors("#Guests");
			VRA.publicContactForm.setFormDataCookie();
		}
	};
	
	this.populateFromContactForm = function() {
	  var
	  data = this.getFormDataCookie("PublicContactForm"),
	  dateParams = {};
	  if(VRA.toType(data)=="object") {
      dateParams = {
        "Check_In":data.Check_In_PublicContactForm,
        "Check_Out":data.Check_Out_PublicContactForm
      };
      if(!this.getFormVal("Num_Adults"))
        this.setFormVal("Num_Adults",data.Guests);
	  }
    if(!this.hasValidDates())
      this.setDates(dateParams);
    if(this.hasValidDates())
      this.updateCalendar();
	};
	
	this.hasBookingDest = function() {
	  return VRA.toType(this.conversionConfig)=="object" && VRA.toType(this.conversionConfig.dest) == "string";
	};
	
	this.continueBooking = function(btn) {
	  var formValidation = this.validateForm();
		if(formValidation.success) {
	    this.setFormDataCookie();
	    $(btn).html('Processing...');
      this.trackConversion(this.conversionConfig);
	    window.location.href = this.conversionConfig.dest;
		}
	};
	
}
PublicBookingForm.prototype = new PublicCustomerForm();