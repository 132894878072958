function InteractiveCalendar(config) {
	
	AppCtrl.call(this,config);

	this.params = {};
	var _this = this;
	this.nonPersistentParams = ["slave","status","firstDate","Check_In","Check_Out","selectedDay","newDates","direction","clearDates","availDates","datesChanged","success","bookingInfo"];
	
	this.setParams = function(params) {
		if (VRA.toType(params) == "object") {
			delete params.html;
			$.each(this.nonPersistentParams,function(idx,val){
				if(!params[val])
					delete _this.params[val];
			});
			$.extend(this.params,params);
			this.propertyID = this.params.id;
		}
	};
	this.init = function(params) {
		this.setParams(params);
		if(!this.params || !this.params.noUI)
			this.setMouseEvents();
		this.indicateCurrentDate();
		this.enable();
		$(this.containerID).show().find("button").removeClass("invisible");
	};
	this.show = function(params,feedbackElement) {
		this.disable();
		this.setParams(params);
		this.hideCurrentDateIndicator();
		if(typeof feedbackElement == "undefined")
			feedbackElement = this.getElements(".nav:first");
		this.callPhp({
			cls:this.phpClass,
			mthd:"getOutput",
			params:this.params,
			element:this.containerID,
			feedbackElement:feedbackElement,
			callback:function(data) {
				_this.init(data);
			}
		});
	};
	this.getDayInfo = function(e, el, allowSelectCheckOut) {
		var elId, parts, elPart, elOtherPart;
		var result = {};
		elId = $(el).prop("id");
		if (elId != undefined) {
			parts = elId.split("|");
			if (parts.length == 1) {
				// Mouse is over a full day of a block or available period.
				elPart = parts[0];
			} else if (parts.length == 2) {
				// Mouse is over a split day.
				var off = $(el).offset();
				var x = e.pageX - off.left;
				var y = e.pageY - off.top;
				var w = $(el).width();
				var partNum = x + y > w ? 1 : 0;
				elPart = parts[partNum];
			}
			if (elPart != undefined) {
				if (elPart != 'c' && (parts[1] != 'c' || allowSelectCheckOut)) {
					// Mouse is not over a sub or master unit booking, or over a check-out.
					if (elPart.substr(0, 1) == 'c') {
						// Mouse is over existing booking.
						return {
							id : parseInt(elPart.substr(1))
						};
					} else {
						// Mouse is over available date.
						if (parts.length < 2 || (parts.length > 1 && (parts[1].substr(0, 1) != 'c' || allowSelectCheckOut)))
							return {
								date : elPart
							};
					}
				}
			}
		}
		return result;
	};
	this.saveDayStates = function(dayInfo) {
		this.checkIn = $(this.containerID).find("[id*='|c" + dayInfo.id + "']");
		this.checkOut = $(this.containerID).find("[id*='c" + dayInfo.id + "|']").children().first();
		if($.hasData(this.checkIn))
			this.prevBgCol = $(this.checkIn).css("background-color");
		// alert("check-out pos =
		// "+$(this.checkOut).css("background-position").length+"\ncheck-out
		// pos.x = "+$(this.checkOut).css("background-position-x").length);
		if (this.xyBgPosSupported == undefined && $.hasData(this.checkIn))
			this.xyBgPosSupported = $(this.checkIn).css("background-position-x").length > 0;
		if (this.xyBgPosSupported == undefined && $.hasData(this.checkOut))
			this.xyBgPosSupported = $(this.checkOut).css("background-position-x").length > 0;
		if (this.xyBgPosSupported) {
			if($.hasData(this.checkIn))
				this.prevBgPos = {
					x : $(this.checkIn).css("background-position-x"),
					y : $(this.checkIn).css("background-position-y")
				};
			if($.hasData(this.checkOut))
				this.nextBgPos = {
					x : $(this.checkOut).css("background-position-x"),
					y : $(this.checkOut).css("background-position-y")
				};
		} else {
			if($.hasData(this.checkIn))
				this.prevBgPos = $(this.checkIn).css("background-position");
			if($.hasData(this.checkOut))
				this.nextBgPos = $(this.checkOut).css("background-position");
		}
	};
	this.restoreDayStates = function() {
		if (this.prevBgCol != undefined && $.hasData(this.checkIn))
			$(this.checkIn).css("background-color", this.prevBgCol);
		if (this.xyBgPosSupported) {
			if($.hasData(this.checkIn))
				$(this.checkIn).css({
					"background-position-x" : this.prevBgPos.x,
					"background-position-y" : this.prevBgPos.y
				});
			if($.hasData(this.checkOut))
				$(this.checkOut).css({
					"background-position-x" : this.nextBgPos.x,
					"background-position-y" : this.nextBgPos.y
				});
		} else {
			if($.hasData(this.checkIn))
				$(this.checkIn).css("background-position", this.prevBgPos);
			if($.hasData(this.checkOut))
				$(this.checkOut).css("background-position", this.nextBgPos);
		}
	};
	this.resetDayStates = function() {
		$(this.containerID).find("div[id]").removeClass("hover");
		if(VRA.toType(this.checkIn) == "object" && $.hasData(this.checkIn))
			$(this.checkIn).css({
				'background-color' : '',
				'background-position-x' : '',
				'background-position-y' : ''
			});
		if(VRA.toType(this.checkOut) == "object" && $.hasData(this.checkOut))
			$(this.checkOut).css({
				'background-position' : '',
				'background-position-x' : '',
				'background-position-y' : ''
			});
	};
	this.indicateCurrentDate = function() {
		if ($(".dayNum.today").length > 0 && $("#current_date_indicator").length>0) {
			$("#current_date_indicator").position({
				of : $(".dayNum.today").parent(),
				my : 'left-3 top-3',
				at : 'left top'
			}).addClass("visible");
		} else {
			this.hideCurrentDateIndicator();
		}
	};

	this.hideCurrentDateIndicator = function() {
		$("#current_date_indicator").removeClass("visible");
	};
}
InteractiveCalendar.prototype = new AppCtrl();