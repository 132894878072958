function PublicCustomerForm(type) {
  
	if(type) {
		this.type = type;
		this.phpClass = "Public"+this.type+"Form";
		this.containerID = "#"+this.type.toLowerCase()+"-form-group";
		this.visited = false;
	  this.validDatePatt = "[0-9]{4}-[0-9]{2}-[0-9]{2}";
		AppCtrl.call(this,{aspSpecific:0});
    var setFormVal = this.setFormVal,
    setFormDataCookie = this.setFormDataCookie;
	}
	
	var _this = this;
	
	this.init = function() {
		this.initValidation();
		this.getForm()
		.find(":input")
		.click(function() {
			if(!_this.visited) {
				_this.visited = true;
				VRA.publicWebsite.sendCDM2GA("Form","Customer "+_this.type);
			}
		});
		this.getElements("select").each(function(){
			_this.setSelectColors(this);
		});
		$(this.containerID).on("change","select",function(){
			_this.setSelectColors(this);
		});
		this.show();
    this.initialized = true;
	};

  this.setFormVal = function(id,val,andValidate) {
    setFormVal.call(this, id, val);
    var el = this.getFormElement(id);
    if(el) {
      if(andValidate)
        this.validateInput(el);
      if(el.is('select'))
        this.setSelectColors(el);
    }
  };
	
	this.setSelectColors = function(control) {
		$(control).css("color",$(control).val()>0?"#555":"");
	};
	this.getDatePickerSelector = function(which,display) {
		return "#"+which+"_"+this.phpClass+(display ? "_Display" : "");
	};
	this.setDatePicker = function(which,isoDate) {
		var dt;
    var selIso = this.getDatePickerSelector(which);
    var selDisplay = this.getDatePickerSelector(which,true);
		if(VRA.rgx(isoDate,this.validDatePatt)) {
			var dateParts = isoDate.split("-");
			dt = new Date(dateParts[0],parseInt(dateParts[1])-1,dateParts[2]);
		} else {
			dt = null;
		}
    $(selIso).val(isoDate);
    $(selDisplay).datepicker("setDate",dt);
	};
	
	this.getDatePickerDateISO = function(which) {
		var sel = this.getDatePickerSelector(which);
		return $(sel).val();
	};
	this.setDates = function(data) {
	  data = data || {};
    var checkIn = data.Check_In || VRA.SUCM.get('searchFormParams.Search_First_Date');
    var checkOut = data.Check_Out || VRA.SUCM.get('searchFormParams.Search_Last_Date');
		if(checkIn) {
			this.setDatePicker("Check_In",checkIn);
			this.setDatePicker("Check_Out",checkOut);
		} else {
			this.clearDates();
		}
	};
	this.initDatePickers = function(options) {
		var 
		datePickers = this.getElements(".date-pickers"),
		config = $(datePickers).data(),
		firstDateBaseName = "Check_In_"+this.phpClass,
		firstDateInput = $("#"+firstDateBaseName),
		firstDateDisplay = $("#"+firstDateBaseName+"_Display"),
		firstDate = firstDateInput.val(),
		firstDatePickerShown = false,
		lastDateBaseName = "Check_Out_"+this.phpClass,
		lastDateInput = $("#"+lastDateBaseName),
		lastDateDisplay = $("#"+lastDateBaseName+"_Display"),
		lastDate = lastDateInput.val(),
		lastDatePickerShown = false,
		bothConfig = {
			format: "m/d/yyyy",
			autoclose:true
		};
		this.showCalendar = VRA.toType(options)=="object" && options.showCalendar;
		if(this.showCalendar) {
			bothConfig.beforeShowDay = function(date) {
				var 
				yyyy = date.getFullYear(),
				mm = date.getMonth()+1,
				dd = date.getDate(),
				isoDate = String(10000*yyyy + 100*mm + dd),
				result;
				if(typeof _this.calendarData == "object" && _this.calendarData != null) {
					if($.inArray(isoDate,_this.calendarData.full)>-1 || ($.inArray(isoDate,_this.calendarData["in"])>-1 && $.inArray(isoDate,_this.calendarData.out)>-1)) {
						result = {
							classes:"full",
							enabled:false
						};
					} else if($.inArray(isoDate,_this.calendarData["in"])>-1) {
						result = {
							classes:"in",
							enabled:true
						};
					} else if($.inArray(isoDate,_this.calendarData.out)>-1) {
						result = {
							classes:"out",
							enabled:true
						};
					} else {
						result = undefined;
					}
				}
				return result;
			};
			this.callPhp({
				cls:this.phpClass,
				mthd:"getCalendarData",
				params:this.getFormParams(),
				callback:function(data) {
					_this.calendarData = data;
				}
			});
		}
		datePickers.datepicker(bothConfig).find(".input-group-addon").click(function(){
			$(this).siblings(":input").datepicker("show");
		});
		if(firstDate)
			firstDateDisplay.datepicker("setDate",new Date(firstDate+"T00:00:00-10:00"));
    if(VRA.toType(config)=="object") {
      if(config.startdate) {
        var startDate = new Date(config.startdate);
        firstDateDisplay.datepicker("setStartDate", startDate);
        lastDateDisplay.datepicker("setStartDate", startDate);
      }
      if(config.enddate) {
        var endDate = new Date(config.enddate);
        firstDateDisplay.datepicker("setEndDate", endDate);
        lastDateDisplay.datepicker("setEndDate", endDate);
      }
    }
		firstDateDisplay.datepicker()
		.on("show",function(e){
			firstDatePickerShown = true;
		})
		.on("hide",function(e){
			firstDatePickerShown = false;
		})
		.on("changeDate",function(e) {
			var iso = e.format("yyyy-mm-dd");
			firstDateInput.val(iso);
			// Don't auto-set check-out date or update status yet.
			VRA.matchDatePicker(firstDateBaseName+"_Display", lastDateBaseName+"_Display", false, 0);
			if(firstDatePickerShown && iso!="" && lastDateInput.val()!="" && lastDateInput.val()!=iso)
				_this.datePickerSelected(this,iso);
		});
		if(lastDate)
			lastDateDisplay.datepicker("setDate",new Date(lastDate+"T00:00:00-10:00"));
		lastDateDisplay.datepicker()
		.on("show",function(e){
			lastDatePickerShown = true;
		})
		.on("hide",function(e){
			lastDatePickerShown = false;
		})
		.on("changeDate",function(e) {
			var iso = e.format("yyyy-mm-dd");
			lastDateInput.val(iso);
			if(lastDatePickerShown)
				_this.datePickerSelected(this,iso);
		});
	};
	this.datePickerSelected = function(dp,isoDate) {};
	this.initValidation = function() {
		$(this.containerID).on("change keyup","[pattern]",function(){
			_this.validateInput(this);
		});
	};
	this.validateInput = function(input,valid) {
		if(typeof valid == "undefined") {
			var patt = $(input).prop("pattern") || $(input).attr("pattern");
			if(patt)
			  valid = VRA.rgx($(input).val(),patt);
		}
		$(input).
		closest(".form-group").
		toggleClass("has-success",valid).
		toggleClass("has-error",!valid).
		find(".form-control-feedback").
		removeClass("glyphicon-asterisk").
		toggleClass("glyphicon-ok",valid).
		toggleClass("glyphicon-warning-sign",!valid);
		return valid;
	};
	this.clearDates = function() {
		this.getElements(".date-picker-input").datepicker("setDate",null);
		this.clearValidation(".date-picker-input");
	};
	this.clearValidation = function(sel) {
		$(sel).
		closest(".form-group").
		removeClass("has-success has-error").
		find(".form-control-feedback").
		addClass("glyphicon-asterisk").
		removeClass("glyphicon-ok glyphicon-warning-sign");
	};
	this.validateForm = function(){
		$(this.containerID).find("[pattern]").each(function(){
			_this.validateInput(this);
		});
	};
	this.trackConversion = function(data) {
	  if(VRA.toType(data)=="object") {
      // Track lead in Google Analytics:
	    if(data.goalType)
	      VRA.publicWebsite.sendCDM2GA("Goal",data.goalType);
      if(data.goalUrl)
        ga("send","pageview",data.goalUrl);
      if(VRA.toType(data.adwordsCfg)=="object")
        google_trackConversion({
          google_conversion_id: data.adwordsCfg.id,
          google_conversion_label: data.adwordsCfg.label,
          google_remarketing_only: false
        });
      // Track lead in Bing:
      this.getForm().append('<iframe src="/bing_tracking.php" style="width:1px;height:1px;" seamless></iframe>');
	  }
	};
  this.setFormDataCookie = function() {
    setFormDataCookie.call(this, null, 30);
  };
}
PublicCustomerForm.prototype = new AppCtrl();