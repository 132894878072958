function PublicContactForm() {
	PublicCustomerForm.call(this,"Contact");
	var _this = this;
	var init = this.init;
	
	this.init = function(pID) {
    this.initDatePickers();
    this.populate();
    this.getForm()
    .submit(function(e) {
      e.preventDefault();
      _this.submit();
    });
		init.call(this);
    $('img').unveil();
	};
  
  this.populate = function() {
    var dateParams = {};
    var data = this.getFormDataCookie();
    if(VRA.toType(data)=="object") {
      dateParams = {
        "Check_In":data.Check_In_PublicContactForm,
        "Check_Out":data.Check_Out_PublicContactForm
      };
      this.setFormVal('Guests',data.Guests || 0);
      if(VRA.toType(data.PrimaryC)=="object") {
        this.setFormVals(data.PrimaryC, 'PrimaryC');
      }
    }
    this.setDates(dateParams);
  };
  
  this.submit = function() {
    if(!this.formIsEnabled())
      return false;
    this.getForm().find(":submit").val("Processing...");
    this.setFormDataCookie();
    var params = this.getFormParams() + "&submitted=1";
    this.disableForm();
    this.callPhp({
      cls:this.phpClass,
      mthd:"getOutput",
      params:params,
      element:this.containerID+" form",
      callback:function(data) {
        _this.submitted(data);
      }
    });
  };
	
	this.submitted = function(data) {
		if(data.success) {
		  if(VRA.toType(data.conversionConfig)=="object")
		    this.trackConversion(data.conversionConfig);
			VRA.publicWebsite.scrollToElement('#contact-form-group');
		} else {
			this.validateForm();
		}
	};
}
PublicContactForm.prototype = new PublicCustomerForm;