function MapZoomControl(div, map) {

	// Get the control DIV. We'll attach our control UI to this DIV.
	var controlDiv = div;

	// Set CSS for the controls.
	$(controlDiv).addClass('zoom_controls');

	var zoomin = document.createElement('button');
	zoomin.title = 'Click to zoom in';
	$(zoomin).addClass("btn btn-default");
	controlDiv.appendChild(zoomin);

	var zoominText = document.createElement('div');
	zoominText.innerHTML = '<span class="glyphicon glyphicon-plus"></span>';
	zoomin.appendChild(zoominText);

	var zoomout = document.createElement('button');
	zoomout.title = 'Click to zoom out';
	$(zoomout).addClass("btn btn-default");
	controlDiv.appendChild(zoomout);

	var zoomoutText = document.createElement('div');
	zoomoutText.innerHTML = '<span class="glyphicon glyphicon-minus"></span>';
	zoomout.appendChild(zoomoutText);

	// Setup the click event listeners for zoom-in, zoom-out:
	google.maps.event.addDomListener(zoomout, 'click', function() {
		var currentZoomLevel = map.getZoom();
		if (currentZoomLevel != 0) {
			map.setZoom(currentZoomLevel - 1);
		}
	});

	google.maps.event.addDomListener(zoomin, 'click', function() {
		var currentZoomLevel = map.getZoom();
		if (currentZoomLevel != 21) {
			map.setZoom(currentZoomLevel + 1);
		}
	});
}