function PublicBookingCalendar() {
	
	InteractiveCalendar.call(this,{aspSpecific:0});

	var _this = this;
	var init = this.init;
	var show = this.show;
	this.phpClass = "PublicBookingCalendar";
	this.containerID = "#calendar";
	
	this.init = function(params) {
		init.call(this,params);
		this.updateBookingForm();
	};
	
	this.setMouseEvents = function() {
		$(this.containerID)
		.find("div[id]").click(function(e) {
      e.stopPropagation();
    }).tap(function(e) {
			if(_this.isEnabled()) {
				var dayInfo = _this.getDayInfo(e, this, true);
				if (VRA.toType(dayInfo)=="object" && dayInfo.date != undefined)
					_this.daySelected(dayInfo);
			}
		});
	};
	
	this.show = function(params) {
	  params = params || this.params || {};
	  this.showProcessingFeedback();
		delete(params.msg);
		params.propertyID = this.propertyID;
		show.call(this,params,null);
	};
	
	this.showProcessingFeedback = function() {
    this.getElements(".month").addClass('loading');
	};
	
	this.daySelected = function(dayInfo) {
	  VRA.publicBookingForm.setStatusDisplay('CHECKING AVAILABILITY');
		this.show({selectedDay:_this.getIsoDate(dayInfo.date),msg:this.params.privateCalendar ? "CHECKING DATES" : "CALCULATING RATE"});
	};
	
	this.getIsoDate = function(abbr) {
		if(typeof abbr == "string")
			return "20"+abbr.substr(0,2)+"-"+abbr.substr(2,2)+"-"+abbr.substr(4,2);
	};
	
	this.updateBookingForm = function() {
		if(!this.params.slave && this.params.datesChanged) {
		  var dates, validDates = false;
		  if(this.params.status)
		    VRA.publicBookingForm.setStatusDisplay(this.params.status);
			if(this.params.clearDates) {
				VRA.publicBookingForm.clearDates();
			} else {
				if(VRA.toType(this.params.availDates)=="object") {
					dates = this.params.availDates;
					validDates = true;
				} else {
					if(this.params.Check_In)
						dates = {Check_In:this.params.Check_In};
				}
				VRA.publicBookingForm.setDates(dates);
				if(!dates.Check_Out)
					VRA.publicBookingForm.clearValidation("#booking-form-group .date-picker-input");
				else
					VRA.publicBookingForm.validateInput("#booking-form-group .date-picker-input",validDates);
	      VRA.publicBookingForm.update();
			}
			VRA.publicBookingForm.populatePublicContactForm();
		}
	}
	
}
PublicBookingCalendar.prototype = new InteractiveCalendar();