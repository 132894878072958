function MapManager() {
	this.phpClass = "MapManager";
	this.singleton = true;
	this.apiLoaded = false;
	this.mapSlots = {};
	this.lastInfoWindow = false;
	this.townMarkerZoom = 8;
	this.markerDims = {
		locale:{
			width:80,
			height:20
		},
		photo:{
			width:30,
			height:22.5
		},
		beach:{
			width:24,
			height:21
		},
		thingtodo:{
			width:24,
			height:26
		}
	};
	var _this = this;
	
	$(window).resize(function() {
		if(!_this.mapsInitialized)
			_this.initMaps();
	});
	
	this.apiLoadCallback = function() {
		this.apiLoaded = true;
		$.getScript(VRA.dirWsStatic+"javascript/MarkerBundle.min.js",function(){
			_this.initMaps();
		});
	};
	this.addMap = function(config) {
	  if(typeof config == "string")
	    config = $.parseJSON(config);
		if(typeof config.elementID == "string")
			this.mapSlots[config.elementID] = {config:config};
	};
	/**
	 * Initializes one or more Google Maps using a global array configs.
	 * Each item in configs array is an array to be used by the this.initMap function.		
	 */
	this.initMaps = function() {
		if (!this.mapsInitialized && VRA.toType(this.mapSlots)=="object") {
			var atLeastOneVisibleMap = false;
			$.each(this.mapSlots,function(elementID,mapSlot){
				if($("#"+elementID).is(":visible"))
					atLeastOneVisibleMap = true;
			});
			if(!atLeastOneVisibleMap)
				return;
			if(!this.apiLoaded) {
			  if(!this.apiLoadStarted) {
  				this.apiLoadStarted = true;
  				$.getScript(VRA.protocolPrefix+"://maps.googleapis.com/maps/api/js?key="+VRA.googleApiKey+"&sensor=false&callback=VRA.mapManager.apiLoadCallback");
			  }
				return;
			}
			$.each(this.mapSlots,function(elementID,mapSlot){
				$.extend(_this.mapSlots[elementID],_this.initMap(mapSlot.config));
			});
			this.mapsInitialized = true;
			VRA.addWindowListener("resize",this.redrawAll);
		}
	};
	/**
	 * Initializes a Google Map 		using a hash containing:
	 * 		string	elementID			ID of html element to put map into
	 * 		int		Zoom				map zoom level. optional. defaults to 10.
	 * 		string	Bounds				Comma-separated list of lat/lng values for SW and NE corners.
	 * 									eg: "33.585383271,-117.9543486,33.628273995,-117.834185718"
	 * 									Overrides Zoom. 
	 * 		float	Lat					map center latitude
	 * 		float	Lng					map center longitude
	 * 		string	localeName			Must be set if Lat or Lng omitted. (Ex: "Kailua, Oahu, Hawaii")
	 * 		bool	editable			If true, make marker draggable and make map and marker drags
	 * 									change the values of latitude, longitude and zoom in the form.
	 * 		object	mapOpts				Optional overrides for google mapOptions object.
	 * 		object	marker				Optional config for a single marker:
	 * 			string	type				"exact" or "approx" If not set, no marker displayed.
	 * 			array	opts				Optional overrides for google markerOptions object.
	 * 		array	markers				Array of google.maps.Marker class instances.
	 * 		object	markerIconConfigs	Hash with asset types as keys and JSON strings as values.
	 */
	this.initMap = function(config) {
		if(typeof google == "undefined")
			return;
		// If no coordinates, get them from Google:
		if(config.Lat == undefined || config.Lng == undefined) {
			this.setCoordinatesFromAddress(config);
			return 0;
		}
		this.mapInitStart = new Date();
		if(config.Zoom == undefined)
			config.Zoom = 10;
		var mapOptions = {
			zoom : parseInt(config.Zoom),
			maxZoom: 15,
			center : new google.maps.LatLng(config.Lat, config.Lng),
			mapTypeId : google.maps.MapTypeId.ROADMAP,
			mapTypeControl:false,
			backgroundColor:"47a6ea",
			keyboardShortcuts:false,
			panControl:false,
			streetViewControl:false,
			disableDoubleClickZoom:true,
			scrollwheel:false,
			zoomControl:false,
		    styles:[]
		};
		if(!config.editable)
			mapOptions.styles = [ {
				"stylers" : [ {
					"visibility" : "off"
				} ]
			}, {
				"featureType" : "water",
				"stylers" : [ {
					"visibility" : "on"
				},
				{
					"color" : "#47a6ea"
				}]
			}, {
				"featureType" : "landscape",
				"stylers" : [ {
					"visibility" : "on"
				}  ]
			}, {
				"featureType" : "water",
				"elementType" : "labels",
				"stylers" : [ {
					"visibility" : "off"
				} ]
			},{
				"featureType": "poi",
				"stylers": [ { "visibility": "on" } ] },
			{
				"featureType": "poi.government",
				"stylers": [ { "visibility": "off" } ] },
			{
				"featureType": "road.highway",
				"stylers": [ { "visibility": "on" },{ "color": "#FFFFFF" } ]
			},{
				"featureType": "road.arterial",
				"stylers": [ { "visibility": "on" } ]
			}];
		if(VRA.toType(config.mapOpts)=="object") {
			if(typeof config.mapOpts.mapType == "string") {
				config.mapOpts.mapTypeId = google.maps.MapTypeId[config.mapOpts.mapType];
				delete config.mapOpts.mapType;
			}
		}
		$.extend(mapOptions,config.mapOpts);
		var hasMarker = config.marker != null;
		if(config.editable) {
			$.extend(mapOptions,{
				scrollwheel:false,
				streetViewControl:true,
				keyboardShortcuts:true,
				streetViewControl:false,
				disableDefaultUI:false,
				draggable:true,
				scrollwheel:true,
				zoomControl:true,
				zoomControlCustom:true,
				disableDoubleClickZoom:false,
			});
		} else if(hasMarker && config.marker.type == "exact") {
			mapOptions.styles.push({
				"featureType" : "road.local",
				"stylers" : [{
					"visibility" : "on"
				}]});
			$.extend(mapOptions,{
				disableDefaultUI:true,
				draggable:false,
				zoomControl:false,
				scrollwheel:false,
				panControl:false,
				streetViewControl:false,
				disableDefaultUI:true,
				disableDoubleClickZoom:true
			});
		} else {
			$.extend(mapOptions,{
				zoomControlCustom:true
			});
		}
		var map = new google.maps.Map(document.getElementById(config.elementID), mapOptions);
		// Add zoom controls to public maps if initial zoom level is not max zoom level:
		if(!config.editable && config.Zoom < mapOptions.maxZoom && mapOptions.zoomControlCustom) {
			var zoomDiv = document.createElement('div');
			var renderZoomControls = new MapZoomControl(zoomDiv, map);
			zoomDiv.index = 1;
			map.controls[google.maps.ControlPosition.RIGHT_TOP].push(zoomDiv);
		}
		var overlay = new google.maps.OverlayView();
		overlay.draw = function() {};
		overlay.setMap(map);
		this.mapSlots[config.elementID].overlay = overlay;
		if(config.editable) {
			google.maps.event.addListenerOnce(map,"idle",function() {
				_this.idleOnceTasks(map);
			});
			google.maps.event.addListener(map,"idle",function() {
				_this.setEditFormFields(map);
			});
		} else {
			google.maps.event.addListenerOnce(map, 'idle', function(){
			    // Map has finished loading.
				_this.mapInitEnd = new Date();
				var mapInitTime = _this.mapInitEnd - _this.mapInitStart;
				// Don't allow zooming out farther than initial zoom:
				_this.mapPosition = map.getCenter();
				map.setOptions({
					minZoom:map.getZoom()
				});
				_this.idleOnceTasks(map);
			});
			google.maps.event.addListener(map, 'dragstart', function() {
				this.dragStart = new Date();
			});
			google.maps.event.addListener(map, 'drag', function() {
				var now = new Date();
				if(new Date(now - this.dragStart)>1000) {
					this.dragStart = now;
					_this.updateAssetMarkers(map);
				}
			});
			google.maps.event.addListener(map, 'dragend', function() {
				_this.updateAssetMarkers(map);
			});
			if(!(hasMarker && config.marker.type == "exact"))
				google.maps.event.addListener(map, 'dblclick', function(evt) {
					_this.centerAndZoom(map,evt);
				});
			google.maps.event.addListener(map,"zoom_changed",function() {
				setTimeout(function(){
					_this.updateAssetMarkers(map)
				},100);
				/*
				// Show or hide "Zoom to see more" hint text:
				$(".zoom_hint").toggle(zoom < _this.townMarkerZoom);
				*/
			});
		}
		var bounds = this.getBounds(config);
		if(VRA.toType(bounds)=="object")
			this.fitBoundsBetter(map,bounds);
		if(!config.editable) {
			// Restrict panning by checking if center viewport are still within initial bounds:
			this.strictBounds = bounds;
			google.maps.event.addListener(map, "drag", function() {
				_this.restrictPanning(map);
			});
		}
		var latLng = new google.maps.LatLng(config.Lat, config.Lng);
		this.iconDir = VRA.dirWsImages+"icons/maps/";
		if(VRA.toType(config.markerIconConfigs)=="object") {
			this.iconConfigs = {};
			this.iconSpriteUrl = this.iconDir+"sprites.png";
			$.each(config.markerIconConfigs,function(assetType,iconConfig){
				if(VRA.toType(iconConfig)=="object") {
					_this.iconConfigs[assetType] = iconConfig;
				} else if(typeof iconConfig == "string" && iconConfig.length > 0) {
				  try {
				    _this.iconConfigs[assetType] = $.parseJSON(iconConfig);
				  } catch(e) {
				      VRA.sendToRaygun(e, {
				        method: 'MapManager.initMap',
				        task: 'parsing iconConfig',
				        iconConfig: iconConfig
				      });
				  }
				}
			});
		}
		var singleMarker;
		if(hasMarker) {
			var markerOptions = {
				map : map,
				clickable : false
			};
			if(config.editable)
				$.extend(markerOptions,{draggable:true,clickable:true});
			// Add property location indicator (circle) if propLat and propLng set:
			if(config.marker.type == "approx") {
				var approxAreaMarkerOptions = {
					strokeColor : "#00FF00",
					strokeOpacity : 0.8,
					strokeWeight : 2,
					fillColor : "#00FF00",
					fillOpacity : 0.35,
					center : latLng,
					radius : 5000/mapOptions.zoom
				};
				$.extend(markerOptions,approxAreaMarkerOptions,config.marker.opts);
				singleMarker = new google.maps.Circle(markerOptions);
				google.maps.event.addListener(map, 'zoom_changed', function() {
					singleMarker.setRadius(5000/map.getZoom());
				});
			} else {
				var exactMarkerOptions = {
					position : latLng
				};
				$.extend(markerOptions,exactMarkerOptions,config.marker.opts);
				singleMarker = this.createMarker(markerOptions,markerOptions);
			}
			if(config.editable) {
				google.maps.event.addListener(singleMarker, 'dragend', function(e) {
					map.panTo(e.latLng);
					_this.setEditFormFields(map);
				});
			}
		}
		var result = {
			map:map,
			config:config,
			mapOptions:mapOptions
		};
		/*
		 * If re-enabling places photos layer, add the following to above var "result:"
		 * ,
			placesService:new google.maps.places.PlacesService(map),
			placePhotos:[]
		 */
		return result;
	};
	this.idleOnceTasks = function(map) {
		// Load asset markers from server:
		this.addMarkers(map);
		this.loadAssetMarkers(map);
	};
	this.updateAssetMarkers = function(map) {
		var mapContainerID = $(map.getDiv()).prop("id");
		if(VRA.toType(_this.mapSlots[mapContainerID].markerSets)=="object") {
			this.mapSlots[mapContainerID].markerSets = this.addMarkers(map);
			var zoom = map.getZoom();
			// _this.updatePlacesPhotos(map);
			// Show or hide town markers depending on zoom level:
			var markerSets = _this.mapSlots[mapContainerID].markerSets;
			if(VRA.toType(markerSets)=="object") {
				if(VRA.toType(markerSets.town)=="array") {
					var vis = zoom >= _this.townMarkerZoom;
					$.each(markerSets.town,function(index,marker) {
						marker.setVisible(vis);
					});
				}
				if(VRA.toType(markerSets.poi)=="array") {
					var vis = zoom>12;
					$.each(markerSets.poi,function(index,marker) {
						marker.setVisible(vis);
					});
				}
			}
		}
	};
	this.latLng2point = function(overlay,latLng) {
		var result = overlay.getProjection().fromLatLngToDivPixel(latLng);
		return result;
	};
	this.loadAssetMarkers = function(map) {
		var mapContainerID = $(map.getDiv()).prop("id");
		var cacheID = this.mapSlots[mapContainerID].config.asset_markers_cache_id;
		if(typeof cacheID == "string")
			this.callPhp({
				cls : null,
				mthd : "getMemCacheItem",
				params : "param0="+cacheID,
				webCache: true,
				callback : function(data) {
					if(VRA.toType(data)=="object" && data !== null) {
						$.extend(_this.mapSlots[mapContainerID].config.markerIconConfigs,data.markerIconConfigs);
						if(VRA.toType(data.markers)=="array") {
							if(VRA.toType(_this.mapSlots[mapContainerID].config.markers)=="array")
								$.merge(_this.mapSlots[mapContainerID].config.markers,data.markers);
							else
								_this.mapSlots[mapContainerID].config.markers = data.markers;
						}
						_this.mapSlots[mapContainerID].markerSets = _this.addMarkers(map,true);
						var loadEnd = new Date();
						var loadTime = loadEnd - _this.mapInitEnd;
					}
				}
			});
	};
	this.addMarkers = function(map,assetMarkersOnly) {
		var mapContainerID = $(map.getDiv()).prop("id");
		var config = this.mapSlots[mapContainerID].config;
		var mapOptions = this.mapSlots[mapContainerID].mapOptions;
		if(VRA.toType(config.markers)=="array") {
			if(VRA.toType(this.mapSlots[mapContainerID].markerSets)=="object")
				this.removeMarkers(this.mapSlots[mapContainerID].markerSets.all);
			var 
			markerSets = {all:[],clusterable:[],photo:[],town:[],poi:[]},
			markerRects = {icon:[],locale:[]},
			validMarker,
			newRect,
			pos,
			dim,
			markOpts,
			newMarker;
			var mapOverlay = this.mapSlots[mapContainerID].overlay;
			var mapBounds = map.getBounds();
			var showTownNames = map.getZoom() >= this.townMarkerZoom;
			$.each(config.markers,function(index,markerConfig) {
				markOpts = {
					map:map,
					position:new google.maps.LatLng(markerConfig.Lat,markerConfig.Lng)
				};
				if(!mapBounds.contains(markOpts.position)) {
					validMarker = false;
				} else {
					validMarker = true;
					if(VRA.toType(markerConfig.metaData)=="object") {
						if(typeof markerConfig.metaData.photoMarkerSrc == "string" || markerConfig.metaData.markerType == "beach" || markerConfig.metaData.markerType == "thingtodo") {
							// Omit photo and icon markers that overlap ones already on the map:
							dim = _this.markerDims[typeof markerConfig.metaData.photoMarkerSrc == "string" ? 'photo' : markerConfig.metaData.markerType];
							pos = _this.latLng2point(mapOverlay,markOpts.position);
							newRect = {
								left:pos.x,
								top:pos.y,
								right:pos.x+dim.width,
								bottom:pos.y+dim.height
							};
							if(_this.intersectsAnyRects(markerRects.icon,newRect))
								validMarker = false;
							if(validMarker)
								markerRects.icon.push(newRect);
						} else if(!assetMarkersOnly && typeof markerConfig.metaData.geo_level == "string") {
							// Omit town markers if zoomed out too much:
							if(markerConfig.metaData.geo_level == "town" && !showTownNames) {
								validMarker = false;
							} else {
								// Omit locale labels that overlap ones already on the map:
								dim = _this.markerDims.locale;
								pos = _this.latLng2point(mapOverlay,markOpts.position);
								newRect = {
									left:pos.x,
									top:pos.y,
									right:pos.x+dim.width,
									bottom:pos.y+dim.height
								};
								if(_this.intersectsAnyRects(markerRects.locale,newRect))
									validMarker = false;
							}
							if(validMarker) {
								markerRects.locale.push(newRect);
								markOpts.zIndex = google.maps.Marker.MAX_ZINDEX;
							}
						} else if(assetMarkersOnly || (markerConfig.metaData.markerType == "rentals" || markerConfig.metaData.markerType == "condos" || markerConfig.metaData.markerType == "hotels")) {
							validMarker = false;
						}
					}
				}
				if(validMarker) {
					newMarker = _this.createMarker(markOpts,markerConfig);
					var metaData = newMarker.get("metaData");
					markerSets.all.push(newMarker);
					if(VRA.toType(metaData)=="object") {
						if(metaData.geo_level == "town")
							markerSets.town.push(newMarker);
						else if(metaData.markerType == "beach" || metaData.markerType == "thingtodo")
							markerSets.poi.push(newMarker);
						else if(typeof metaData.photoMarkerSrc == "string")
							markerSets.photo.push(newMarker);
					}
					if(markerConfig.cluster) {
						newMarker.setMap(null);
						markerSets.clusterable.push(newMarker);
					}
				}
			});
			if(markerSets.clusterable.length > 0) {
				var mcOpts = {
					averageCenter:true,
					minimumClusterSize:3,
					gridSize:130,
					maxZoom:mapOptions.maxZoom-2,
					calculator:function(markers,styleCount) {
						var markerTypes = {}, markerType, labelLines = [], label, metaData;
						$.each(markers,function(index,marker) {
							metaData = marker.get("metaData");
							if(VRA.toType(metaData)=="object") {
								markerType = metaData.markerType;
								if(typeof markerType == "string") {
									if(VRA.toType(markerTypes[markerType])=="object")
										markerTypes[markerType].count++;
									else
										markerTypes[markerType] = {labels:metaData.markerTypeLabels,count:1};
								}
							}
						});
						$.each(markerTypes,function(markerType,data) {
							labelLines.push(data.count+" "+(data.count>1 ? data.labels.plural : data.labels.singular));
						});
						label = labelLines.join("<BR/>");
						return {
							index:5,
							text:label
						};
					},
					styles:
						[{
							url:this.iconDir+"m1.png",
							width:53,
							height:52,
							textSize:10,
							textColor:"0B85A8",
							textDecoration:"underline",
							fontWeight:"bold"
						},{
							url:this.iconDir+"m2.png",
							width:56,
							height:55,
							textSize:11,
							textColor:"white",
							textDecoration:"underline",
							fontWeight:"bold",
						},{
							url:this.iconDir+"m3.png",
							width:66,
							height:65,
							textSize:11
						},{
							url:this.iconDir+"m4.png",
							width:78,
							height:77,
							textSize:11,
							textColor:"black",
							textDecoration:"underline",
							fontWeight:"bold"
						},{
							width:100,
							height:50,
							textSize:11,
							textColor:"white",
							fontWeight:"bold"
						}]
				};
				this.markerCluster = new MarkerClusterer(map,[],mcOpts);
				google.maps.event.addListener(this.markerCluster, "clusteringend", function(cluster) {
					$(".map_cluster").toggleClass("offset",map.getZoom() < 9);
				});
				this.markerCluster.addMarkers(markerSets.clusterable);
			}
			return markerSets;
		}
	};
	this.intersectsAnyRects = function(rects,newRect) {
		var result = false;
		$.each(rects,function(index,rect){
			if(_this.rectsIntersect(rect, newRect)) {
				result = true;
				return false;
			}
		});
		return result;
	};
	this.createMarker = function(markOpts,markerConfig) {
		var commonMarkerOptNames = ["title","cursor","flat","labelContent","labelAnchor","labelClass"];
		var marker, meta = markerConfig.metaData;
		$.each(commonMarkerOptNames,function(index,optName){
			if(markerConfig[optName] != undefined)
				markOpts[optName] = markerConfig[optName];
		});
		if(VRA.toType(meta)=="object" && meta.hasIcon) {
			// Has an icon.
			var
			iconConfig = this.iconConfigs[meta.markerType].icon,
			shadowConfig = this.iconConfigs[meta.markerType].shadow;
			if(VRA.toType(iconConfig)=="object" && iconConfig != null)
				markOpts.icon = {
					url:this.iconSpriteUrl,
					size:new google.maps.Size(iconConfig.size.w, iconConfig.size.h),
					origin:new google.maps.Point(iconConfig.origin.x, iconConfig.origin.y),
					anchor:new google.maps.Point(iconConfig.anchor.x, iconConfig.anchor.y)
				};
			if(VRA.toType(shadowConfig)=="object" && shadowConfig != null)
				markOpts.shadow = {
					url:this.iconSpriteUrl,
					size:new google.maps.Size(shadowConfig.size.w, shadowConfig.size.h),
					origin:new google.maps.Point(shadowConfig.origin.x, shadowConfig.origin.y),
					anchor:new google.maps.Point(shadowConfig.anchor.x, shadowConfig.anchor.y)
				};
		}
		// Set marker anchor if any:
		if(VRA.toType(markerConfig.labelAnchor)=="object")
			markOpts.labelAnchor = new google.maps.Point(markerConfig.labelAnchor.x, markerConfig.labelAnchor.y);
		if(typeof markerConfig.labelContent == "string") {
			marker = new MarkerWithLabel(markOpts);
		} else if(typeof meta.photoMarkerSrc == "string") {
			markOpts.src = meta.photoMarkerSrc;
			marker = this.getPhotoMarker(markOpts);
		} else {
			marker = new google.maps.Marker(markOpts);
		}
		// Set meta-data:
		if(VRA.toType(meta)=="object")
			marker.set("metaData",meta);
		// Initialize info window if any:
		if(VRA.toType(markerConfig.infoWindow)=="object") {
			var infoWindow = new google.maps.InfoWindow(markerConfig.infoWindow);
			this.customizeInfoWindowBehavior(marker,infoWindow);
		} else if(VRA.toType(meta)=="object" && typeof meta.hyperLink == "string") {
			// This is a locale marker, so hyperlink to locale page:
			google.maps.event.addListener(marker, 'click', function() {
				var metaData = marker.get("metaData");
				if(VRA.toType(metaData)=="object" && typeof metaData.hyperLink == "string")
					window.location.assign(metaData.hyperLink);
			});
			google.maps.event.addListener(marker, 'mouseover', function() {
				var newClass = marker.get("labelClass");
				if(newClass.indexOf("hover")==-1)
					newClass += " hover";
				marker.set("labelClass",newClass);
			});
			google.maps.event.addListener(marker, 'mouseout', function() {
				var newClass = marker.get("labelClass");
				if(newClass.indexOf("hover")>-1)
					newClass = newClass.replace(" hover","");
				marker.set("labelClass",newClass);
			});
		}
		return marker;
	};
	this.customizeInfoWindowBehavior = function(marker,infoWindow) {
		google.maps.event.addListener(marker, "click", function() {
			var map = marker.getMap();
			_this.mapPosition = map.getCenter();
			if(VRA.toType(_this.lastInfoWindow)=="object")
				_this.lastInfoWindow.close();
			infoWindow.open(map,marker);
			_this.lastInfoWindow = infoWindow;
		});
		var map = marker.getMap(); // Not redundant.
		infoWindow.set("mapContainerID",$(map.getDiv()).prop("id"));
		google.maps.event.addListener(infoWindow, "closeclick", function() {
			var mapContainerID = infoWindow.get("mapContainerID");
			var map = _this.mapSlots[mapContainerID].map;
			map.panTo(_this.mapPosition);
		});
	};
	this.getPhotoMarker = function(config) {
		return new RichMarker({
			map:config.map,
			position:config.position,
			flat:true,
			content:'<img class="photo_marker" title="'+config.title+'" src="'+config.src+'">'
		});
	};
	this.removeMarkers = function(markers) {
		$.each(markers,function(index,marker){
			marker.setMap(null);
		});
	};
	this.restrictPanning = function(map) {
	    if(VRA.toType(_this.strictBounds)=="object" && _this.strictBounds.contains(map.getCenter())) {
	        // still within valid bounds, so save the last valid position
	        _this.lastValidCenter = map.getCenter();
	        return; 
	    }
	    // not valid anymore => return to last valid position
	    map.setCenter(_this.lastValidCenter);
	};
	this.getBounds = function(config) {
		// Set bounds:
		if(VRA.toType(config.newBounds)=="object") {
			// New bounds have just been set by GeoCoder.
			VRA.notificationManager.notify({
				Cat:"warning",
				Title:"Map Position NOT YET SAVED",
				Message:"The map position has just been set using the Google Geocoding Service. Please  adjust the position and zoom level of the map if necessary, and click [Save Changes]."
			});
			return config.newBounds;
		} else if(typeof config.Bounds == "string" && config.Bounds.length) {
			/*
			 * Bounds were loaded from database as comma-separated string,
			 * so convert to google maps LatLngBounds object.
			 */
			return this.string2bounds(config.Bounds);
		}
	};
	this.redrawAll = function() {
		$.each(_this.mapSlots,function(elementID,mapSlot){
			if($("#"+elementID).is(":visible"))
				_this.redraw(elementID);
			});
	}
	this.redraw = function(elementID) {
		var map = this.mapSlots[elementID].map;
		var config = this.mapSlots[elementID].config;
		if(VRA.toType(map)=="object" && VRA.toType(config)=="object") {
			this.fitBoundsBetter(map,this.getBounds(config));
			google.maps.event.trigger(map, 'resize');
			this.fitBoundsBetter(map,this.getBounds(config));
			google.maps.event.trigger(map, 'resize');
			map.setCenter(this.mapSlots[elementID].mapOptions.center);
		}
	};
	this.fitBoundsBetter = function(map,bounds) {
		if(VRA.toType(map)=="object") {
			if(typeof bounds != "object")
				bounds = map.getBounds();
			map.fitBounds(bounds); // does the job asynchronously
			google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
				 var newSpan = map.getBounds().toSpan(); // the span of the map set by Google fitBounds (always larger by what we ask)
				 var askedSpan = bounds.toSpan(); // the span of what we asked for
				 var latRatio = (newSpan.lat()/askedSpan.lat()) - 1; // the % of increase on the latitude
				 var lngRatio = (newSpan.lng()/askedSpan.lng()) - 1; // the % of increase on the longitude
				 // if the % of increase is too big (> to a threshold) we zoom in
				 if (Math.min(latRatio, lngRatio) > 0.46) {
					 // 0.46 is the threshold value for zoming in. It has been established empirically by trying different values.
					 this.setZoom(this.getZoom() + 1);
				 }
			});
		}
	};
	this.setCoordinatesFromAddress = function(config) {
		if(typeof config.localeName == "string") {
			var newConfig = config;
			var geoCoder = new google.maps.Geocoder();
			geoCoder.geocode(
				{
					"address":newConfig.localeName
				},
				function(results,status){
					if(status == google.maps.GeocoderStatus.OK) {
						var geom = results[0].geometry;
						newConfig.Lat = geom.location.lat();
						newConfig.Lng = geom.location.lng();
						if(newConfig.Lat != null && newConfig.Lng != null) {
							newConfig.newBounds = geom.viewport;
							_this.mapSlots[newConfig.elementID].config = newConfig;
							var mapInitResult = _this.initMap(newConfig);
							_this.mapSlots[newConfig.elementID].map = mapInitResult.map;
						}
					} else {
						VRA.notificationManager.notify({
							Cat:"error",
							Message:"GeoCoding failed because: "+status
						});
					}
				}
			);
		}
	};
	this.changeMapAddress = function(elementID,address) {
		var config = _this.mapSlots[elementID].config;
		config.localeName = address;
		this.setCoordinatesFromAddress(config);
	};
	/*
	 * Convert a comma-separated list of lat/lng values for SW and NE corners to a LatLngBounds
	 */
	this.string2bounds = function(str) {
		var coords = str.split(",");
		if(coords[0]!="" && coords[1]!="" && coords[2]!="" && coords[3]!="" && coords[0]!=coords[2] && coords[1]!=coords[3]) {
			var sw = new google.maps.LatLng(coords[0], coords[1]);
			var ne = new google.maps.LatLng(coords[2], coords[3]);
			return new google.maps.LatLngBounds(sw,ne);
		}
	};
	/*
	 * Convert a google maps LatLngBounds object
	 * to a comma-separated list of lat/lng values for SW and NE corners.
	 */
	this.bounds2string = function(bounds) {
		var sw = bounds.getSouthWest();
		var ne = bounds.getNorthEast();
		return sw.lat()+","+sw.lng()+","+ne.lat()+","+ne.lng();
	};
	this.rectsIntersect = function(r1, r2) {
		return r1.left < r2.right && 
			r1.right > r2.left && 
			r1.top < r2.bottom && 
			r1.bottom > r2.top;
	};
	this.setEditFormFields = function(map) {
		var center = map.getCenter();
		$("#Map\\[Lat\\]").val(center.lat());
		$("#Map\\[Lng\\]").val(center.lng());
		$("#Map\\[Zoom\\]").val(map.getZoom());
		$("#Map\\[Bounds\\]").val(this.bounds2string(map.getBounds()));
	};
	this.centerAndZoom = function(map,evt) {
		map.setCenter(evt.latLng);
		map.setZoom(map.getZoom() + 1);
	};
}
MapManager.prototype = new AppCtrl({aspSpecific:0});
VRA.mapManager = new MapManager();