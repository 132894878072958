function SearchPropertyPublic() {
	
	var _this = this;
	this.containerID = "#search-form";
	
	this.initForm = function() {
		VRA.enterify("input#Search_Text",function() {
			_this.clearLocaleVars();
			_this.search();
		});
		this.initAutoComplete("#Search_Text");
		this.initDatePickers();
		this.initRangeSelectors();
		this.initFilters();
		this.initFiltersToggle();
    this.searchBtn = this.getElements(".search button");
    this.filtersBtn = this.getElements(".filters button");
		this.searchBtn.click(function(e) {
      e.stopPropagation();
    }).tap(function(e){
			_this.search();
		});
	};
	
	this.search = function(pageNum) {
		this.searchBtn.html("Searching...");
		var txt = $("#Search_Text").val();
		var id = txt.replace(/[\D]/g, "");
		if (id == txt && parseInt(id) > 0) {
			window.location.assign("/rental_" + parseInt(id) + ".htm");
		} else {
			if(typeof pageNum != "number")
				pageNum = 1;
			$("#pnum").val(pageNum);
			setTimeout(function(){
				_this.update();
			},5);
		}
		return false;
	};
	this.gotoSearchPage = function(pnum) {
		return this.search(pnum);
	};
	this.sort = function() {
		var sort = $("#sorter").val();
		if(sort != "no") {
			$("input[name=\'rf[sort]\']").val(sort);
			this.search();
		}
	};
	
	this.initAutoComplete = function(sel) {
		$.typeahead({
		  input : sel,
		  order : 'asc',
		  display : 'n',
      template : function(q,item) {
        return item.p ? '{{n}}<span class="hint">{{p}}</span>' : '{{n}}'
      },
		  source : {
		    url : [VRA.dirWsStatic+'cache/autocomplete-'+VRA.site+'.json']
		  },
		  offset : true,
		  minLength : 1,
		  cache : 'localStorage',
		  ttl : 7*24*3600000, // 1 week cache time to live
		  href : null,
		  callback : {
	      onResult: function (node, query, result, resultCount) {
	        if(resultCount && VRA.toType(result[0])=="object") {
	          _this.updateLocale(result[0].l ,result[0].i);
	        }
        },
        onClickAfter : function(node, a, item) {
          _this.updateLocale(item.l ,item.i);
        },
        onNavigateAfter : function(node, lis, a, item) {
          _this.updateLocale(item.l ,item.i);
        }
      }
		});
	};
	
	this.updateLocale = function(lvl,id) {
    this.setFormVal("lvl",lvl);
    this.setFormVal("locid",id);
    this.clearLocaleVars();
	};
		  
	this.clearLocaleVars = function() {
		$("#Search_Country_ID,#Search_Zone_ID,#Search_Area_ID,#Search_Town_ID").prop("value","");
	};
	this.clearDates = function() {
		_this.getElements(".date-picker-input").datepicker("setDate",null);
	};
	this.initDatePickers = function() {
		$("#search_date_range").datepicker({
			format:"m/d/yyyy",
			clearBtn:true,
			autoclose:true,
			startDate:new Date()
		}).on("clearDate",this.clearDates).find(".input-group-addon").click(function(){
			$(this).prev(":input").datepicker("show");
		});
		var firstDate = $("#Search_First_Date").val();
		if(firstDate)
			$("#Search_First_Date_Display").datepicker("setDate",this.isoDate2Obj(firstDate));
		$("#Search_First_Date_Display").datepicker().on("changeDate",function(e) {
			$("#Search_First_Date").val(e.format("yyyy-mm-dd"));
			if($("#Search_First_Date").val().length>0 && !$("#Search_Last_Date").val()) {
				VRA.matchDatePicker("Search_First_Date_Display", "Search_Last_Date_Display", null, 0);
				$("#Search_Last_Date_Display").datepicker("show");
			}
		});
		var lastDate = $("#Search_Last_Date").val();
		if(lastDate)
			$("#Search_Last_Date_Display").datepicker("setDate",this.isoDate2Obj(lastDate));
		$("#Search_Last_Date_Display").datepicker().on("changeDate",function(e) {
			$("#Search_Last_Date").val(e.format("yyyy-mm-dd"));
		});
	};
	this.initRangeSelectors = function(){
		$(this.containerID).on("click",".range-selectors a",function(e) {
			var
			selVal = $(this).data("value"),
			btnGroup = $(this).parents(".btn-group"),
			btn = $(btnGroup).children("button"),
			btnValDisplay = $(btn).children(".val"),
			rfName = $(btnGroup).data("field"),
			hiddenField = $("#rf\\["+rfName+"\\]");
			if(rfName.indexOf('Rate')==3 && selVal < 1000)
			  _this.setFormVal("prod","");
			$(hiddenField).val(selVal);
			$(btn).toggleClass("val-displayed",selVal>0);
			$(btnValDisplay).text(selVal>0 ? $(this).text() : "");
		});
	};
	this.initFilters = function() {
    this.getElements(".filters-container .nav-pills li").not(".disabled").click(function(e) {
      e.stopPropagation();
    }).tap(function(e){
      var hiddenField = _this.getElements("input[name*='"+$(this).data("fld")+"']");
      if(hiddenField.length) {
        if($(hiddenField).val() > 0) {
          $(hiddenField).val(0);
          $(this).toggleClass("active",false).find(".glyphicon").toggleClass("glyphicon-check",false).toggleClass("glyphicon-unchecked",true);
        } else {
          $(hiddenField).val(1);
          $(this).toggleClass("active",true).find(".glyphicon").toggleClass("glyphicon-check",true).toggleClass("glyphicon-unchecked",false);
        }
      }
    });
	};
	this.initFiltersToggle = function() {
		this.filtersContainer = this.getElements(".filters-container").first();
		// Make Filters button show or hide filters container:
		this.getElements(".filters .btn").click(function(e) {
      e.stopPropagation();
    }).tap(_this.toggleFilters);
	};
	this.toggleFilters = function() {
    var opening = _this.filtersContainer.css("display") == "none";
    _this.filtersContainer.slideToggle({
      start:function(){
        if(!VRA.publicWebsite.overGridBreakPoint()) {
          if(opening) {
            // Scroll above filters immediately, then down slowly, to get rid of iphone bottom tab bar.
            VRA.scrollToElement(_this.filtersContainer,100,-60);
            $("input#Search_Text").blur();            
          } else {
            VRA.scrollToElement(_this.containerID,100);
          }
        }
      },
      always:function(){
        _this.filtersBtn.html($(this).is(":visible") ? 'Hide Filters' : '<span class="glyphicon glyphicon-filter"></span>Filters');
        _this.searchBtn.html($(this).is(":visible") ? 'Apply Filters' : '<span class="glyphicon glyphicon-search"></span>Search');
      },
      duration:200
    });
	};
	this.update = function() {
    $("#listings.none").hide();
    VRA.SUCM.set('searchFormParams',this.getFormParamsObj(),2);
    window.location.assign("/search_results.php?"+this.getFormParams());
	};
	this.isoDate2Obj = function(isoDate) {
	  if (typeof isoDate == "string") {
	    var dateParts = isoDate.split("-");
	    if (dateParts.length == 3)
	      return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
	  }
	};
}

SearchPropertyPublic.prototype = new AppCtrl;