function PublicWebsite(site) {
	if(typeof site == "string") {
		this.site = site;
		this.phpClass = "PublicWebsite_"+site;
	}
	var _this = this;
	this.singleton = true;
	this.lazyLoadedImages = 0;
	this.gridFloatBreakPoint = 768;
	
	this.sendCDM2GA = function(name,value) {
	  var index = $.inArray(name,["Search_Result","Rental Detail","Form","Goal"])+1;
	  if(index>0) {
	    var gaP = {};
	    gaP["dimension"+index]=value;
	    ga("send","event","CDM","Send",gaP);
	  }
	};
	
	this.init = function() {
    VRA.logPageEvent("PublicWebsite.init BEGIN");
		if(VRA.isTouchDevice())
			$("html").addClass("touch");
    if(VRA.firstLoadOfCssForPageType)
      $('body').addClass('page-type-css-first-load');
    this.extCssCallbacks = $.Callbacks();
    this.extCssCallbacks.add(_this.initMobileTabBar);
    this.setEID();
		this.header = $("header.navbar");
		this.cssCalcIsSupported = this.cssCalcSupported();
		this.initNavBar();
		this.initSearchForm();
		this.initSearchOpenButton();
		this.makeGlobalScrollAdjustments();
		VRA.addWindowListener("scroll",this.makeGlobalScrollAdjustments);
		$(".btn").removeClass("invisible");
		this.initMobileTabBar();
    this.updateFavoritesCountDisplay();
    if(VRA.preHeader == 'home' && !VRA.inBlog)
      this.initHomePage();
    else if(VRA.preHeader == 'general_contact')
      this.initContactForm();
    else if(VRA.onListingsPage)
      this.initListingsPage();
    else if(VRA.onAssetDetailPage)
      this.initAssetDetailPage(VRA.preHeader == 'rental', VRA.assetID, VRA.propBookedOnline);
    if(!VRA.onAssetDetailPage)
      this.setPagePhoneNumber();
    if($('body').hasClass('main-css-loaded'))
      this.extCssCallbacks.fire();
		VRA.logPageEvent("PublicWebsite.init END");
	};
	
	this.initNavBar = function() {
    this.navbar = $(this.header).find('.nav-justified').first();
    this.navbarCollapse = $(this.header).find('.navbar-collapse');
		this.navBarLocLinks = $(this.navbar).find("li > a[data-loc]");
		$(this.navBarLocLinks).click(function(e){
      e.stopPropagation();
    }).tap(function(e) {
			var 
			link = this,
			activeSubNavs = _this.getActiveSubNavs();
			_this.lastShownSubNav = $("#subnav-"+$(link).data("loc"));
			$(_this.navBarLocLinks).parent("li").removeClass("sel");
			$(link).parent("li").addClass("sel");
			if(activeSubNavs.length) {
				$(activeSubNavs).slideUp({
					duration:100,
					always:function(){
						_this.subNavShown();
						$(_this.lastShownSubNav).slideDown({
							duration:101
						});
					}
				});
			} else {
				_this.subNavShown();
				$(_this.lastShownSubNav).slideDown({
					duration:100,
					always:_this.subNavShown
				});
			}
		});
		// Set up nav bar resize adjustments:
		this.makeNavBarResizeAdjustments();
		VRA.addWindowListener("resize",_this.makeNavBarResizeAdjustments);
		this.extCssCallbacks.add(_this.makeNavBarResizeAdjustments);
    if(this.overGridBreakPoint())
      this.navbarCollapse.headroom();
		// Hide subnavs or main nav when clicking elsewhere:
		$(document).on("click touchstart",function(e){
			if(!$(e.target).closest(_this.navbar).length && !$(e.target).closest(".subnav,.navbar-toggle").length && !$(e.target).closest(_this.searchForm).length) {
				if(!_this.hideSubNavs() && !_this.overGridBreakPoint()) {
					_this.toggleMobileNavBar(false);
				}
			}
		});
	};
  this.initMobileTabBar = function() {
    if(!_this.overGridBreakPoint()) {
      $('#tab-bar').add('#tab-bar li,span,i').on('touchmove',function(e){
        e.preventDefault();
      });
      $('#tab-bar > span').click(function(e){
        $(this).addClass('active').siblings().removeClass('active');
      }).not('.places').click(function(){
        _this.toggleMobileNavBar(false);
      });
      $('#tab-bar .places').click(function(e){
        e.stopPropagation();
      }).tap(function(){
        _this.toggleMobileNavBar(true);
      });
      $('#tab-bar .favorites').click(function(e){
        e.stopPropagation();
      }).tap(function(){
        location.href = '/favorites.htm';
      });
      $('#tab-bar .contact').click(function(e){
        e.stopPropagation();
      }).tap(function(){
        location.href = $(this).data('url');
      });
      $('#tab-bar').addClass('initialized',1);
    }
  };
	this.makeNavBarResizeAdjustments = function() {
		if(_this.overGridBreakPoint()) {
			$(".collapse").removeClass("in").removeAttr("style");
		}
	};
	this.overGridBreakPoint = function() {
		return verge.viewportW() >= _this.gridFloatBreakPoint;
	};
	this.mobileNavBarVisible = function() {
	  nav = $(_this.header).find(".nav-justified:visible").first();
    return nav.length > 0;
	};
	this.getActiveSubNavs = function() {
		return $(_this.header).find(".subnav:visible");
	};
	this.toggleMobileNavBar = function(how) {
	  _this.navbarCollapse.collapse(how ? 'show' : 'hide');
	};
	this.hideSubNavs = function(navs) {
		// Hide subnavs if visible:
		if(typeof navs == "undefined")
			navs = _this.getActiveSubNavs();
		if(navs.length) {
			this.hideNavs(navs,_this.subNavHidden);
			return true;
		} else {
			return false;
		}
	};
	this.hideNavs = function(navs,cb) {
		if(!_this.overGridBreakPoint())
			$(navs).hide({
				duration:100,
				direction:"left",
				always:cb
			});		
		else
			$(navs).slideUp({
				duration:100,
				always:cb
			});
	};
	this.subNavShown = function() {
	  if(_this.overGridBreakPoint())
	    $("#search-form.secondary").css("visibility","hidden");
	  $(".collapse").css({"width":"100%","height":$(_this.lastShownSubNav).height()+"px"});
	};
	this.subNavHidden = function() {
    if(_this.overGridBreakPoint())
      $("#search-form.secondary").css("visibility","visible");
    $(".collapse").css({"width":"auto","height":"auto"});
	};
	this.initHomePage = function() {
	  $('#landing').imagesLoaded(this.loadLowPriorityStuff);
    // Set up lazy loading.
		this.loadLazy(200);
		// Set up loading of images in locale product tabs:
		$(".tab-pane").find("img").trigger("unveil");
	};
  /**
   * Initialize TN3 gallery on HP.
   */
  this.initMediaGalleryHomePage = function(data,callback) {
    if(typeof VRA.tn3Config == "object" && typeof data == "object") {
      // Get albums data from .gallery element:
      // Initialize TN3 Gallery:
      this.tn3Gallery = $(".gallery").tn3({
        skinDir : VRA.tn3Config.mainDir+"skins",
        skin : VRA.tn3Config.skin,
        data: data,
        iniAlbum:_this.getHomeAlbumNum(),
        mouseWheel:false,
        autoplay:true,
        responsive:false,
        delay:5000,
        inactive:["albums","album","fullscreen"],
        image:{
          crop:true,
          maxZoom:1,
          defaultTransition:{
            type:"fade",
            duration:750,
            easing:"swing"
          },
          load_end:function(e){
            // Right-justify images:
            $(".tn3b-full-image").css({
              left:"auto",
              right:"0px"
            });
            $(".tn3b-image-in,.tn3b-in-image").css({
              left:"auto",
              right:"0px",
              width:$(".tn3b-image-in").css("width")
            });
          }
        },
        thumbnailer:{
          mode:"bullets",
          align:2
        },
        init:function() {
          $(".tn3b-timer").css("bottom","0");
          if(typeof callback == "function")
            callback();
        }
      }).show().data("tn3");
      VRA.addWindowListener("resize",_this.resizeMediaGalleryHomePage);
    }
  };
  this.resizeMediaGalleryHomePage = function() {
    var album = _this.getHomeAlbumNum();
    if(album != _this.homePageGalleryAlbum) {
      if(_this.tn3Gallery.data[album].imgs.length > 1) {
        _this.homePageGalleryAlbum = album;
        _this.tn3Gallery.showAlbum(album,0);
        $(".gallery").show();
      } else {
        $(".gallery").hide();
      }
    }
  };
  this.getHomeAlbumNum = function() {
    var vpw = verge.viewportW();
    if(vpw>1199)
      album=3;
    else if(vpw>991)
      album=2;
    else if(vpw>767)
      album=1;
    else
      album=0;
    return album;
  };
	this.initMobileFiltersButton = function() {
	  this.filtersButtonMobile = $('.filters-button-mobile');
	  this.filtersButtonMobile.click(function(e){
      e.stopPropagation();
      _this.openSearch(0,function(){
        if(VRA.searchPropertyPublic)
          VRA.searchPropertyPublic.toggleFilters();
      });
    });
	};
	this.initListingsPage = function() {
    VRA.logPageEvent("PublicWebsite.initListingsPage BEGIN");
    $(this.overGridBreakPoint() ? '#landing' : '.value-props').imagesLoaded(this.loadLowPriorityStuff);
		var lazies = $("#listings-group ul.listings-items > li [data-src]").length;
		this.mapAndListingsGroup = $("#map-and-listings-group");
		this.listingsGroup = $("#listings-group");
		this.loadLazy({
			threshold:5000,
			initMapsAfter:Math.min(6,lazies)
		});
    $(".scroll-to-listings").click(function(e){
      e.stopPropagation();
    }).tap(function(){
      _this.scrollToListings();
    });
		this.initRentalSelection();
    if(!this.overGridBreakPoint())
      this.initMobileFiltersButton();
    this.makeListingsScrollAdjustments();
    VRA.addWindowListener("scroll",this.makeListingsScrollAdjustments);
		this.makeListingsResizeAdjustments();
    VRA.addWindowListener("resize",_this.makeListingsResizeAdjustments);
    this.extCssCallbacks.add(_this.makeListingsResizeAdjustments);
		$("#sorter").change(function(){
			VRA.searchPropertyPublic.sort();
		});
		if(lazies == 0)
			this.initMaps();
		$('.value-props.horizontal:visible').find('.col1').find('h2').fitText(1,{minFontSize:"22px",maxFontSize:"28px"});
		this.updateListingsPhoneNumbers();
    VRA.logPageEvent("PublicWebsite.initListingsPage END");
	};
	
	this.scrollToListings = function() {
    this.windowTop = 1;
    if(_this.overGridBreakPoint())
      _this.makeListingsScrollAdjustments();
    _this.scrollToElement("#listings-group",200);
  };
	
	this.makeListingsResizeAdjustments = function() {
	  if(!_this.overGridBreakPoint()) {
	    var thumbs = $("li.property > div > a").first();
	    if(thumbs.length) {
	      $("li.property > div > div").css("left",verge.rectangle(thumbs[0]).right-20)
	    }
	  } else {
	    $("li.property > div > div").css("left","auto");
	  }
	};
	
	this.makeListingsScrollAdjustments = function() {
    if(_this.overGridBreakPoint()) {
  		if(_this.windowTop > 0 && !$(_this.searchForm).hasClass("listings"))
  			$(_this.searchForm).prependTo("#listings-group").addClass("listings");
  		else if(_this.windowTop == 0 && $(_this.searchForm).hasClass("listings"))
  			$(_this.searchForm).insertAfter("header").removeClass("listings");
    } else {
      _this.filtersButtonMobile.toggle(VRA.preHeader != 'favorites' && verge.inViewport(_this.mapAndListingsGroup) && !(_this.searchForm.css('display') == 'block' && verge.inViewport(_this.searchForm)));
    }
	};
	this.initAssetDetailPage = function(isProperty,pID,bookedOnline) {
    VRA.logPageEvent("PublicWebsite.initAssetDetailPage BEGIN");
    this.extCssCallbacks.add(this.initMaps);
		$("#asset-detail h1.name").fitText(2,{minFontSize:"18px",maxFontSize:"26px"});
		this.assetContent = $("#asset-detail > .main");
		this.assetContentMain = this.assetContent.children(".main");
    this.initMediaGalleryAssetDetail();
		if(isProperty) {
			// Init contact buttons:
			$(".customer-form-button").click(function(){
				_this.scrollToElement($(this).attr("href"));
				return false;
			});
			// Init forms:
			this.initContactForm();
			$(".loader-anim").hide();
			VRA.publicBookingForm = new PublicBookingForm();
			VRA.publicBookingForm.init({showCalendar:bookedOnline});
			VRA.publicBookingForm.update();
			VRA.publicBookingCalendar = new PublicBookingCalendar();
			VRA.publicBookingCalendar.show({id:pID,slave:true,privateCalendar:!bookedOnline});
			// Init tooltips:
			if($.fn.tooltip)
				$(".terms a").tooltip();
			// Init short-cut links:
			this.contactFormGroup = $("#contact-form-group");
			this.shortCutBar = this.assetContentMain.children(".shortcuts").first();
			this.shortCutBar.find("a").click(function(e){
	      e.stopPropagation();
	    }).tap(function(e) {
				e.preventDefault();
				_this.shortCutNav(this);
			});
			// Init scrolling and resizing adjustments:
			VRA.addWindowListener("scroll",_this.makeAssetDetailScrollResizeAdjustments);
			VRA.addWindowListener("resize",_this.makeAssetDetailScrollResizeAdjustments);
			$("body").scrollspy({
				target:".main > .main",
				offset:_this.getFixedElementsHeight()+$(_this.shortCutBar).outerHeight()+70
			});
			this.setPagePhoneNumber(pID);
	    this.extCssCallbacks.add(_this.makeAssetDetailScrollResizeAdjustments);
		}
    this.initSimilarAssets();
		// Init favorites button:
		$("button.favorites")
		.toggleClass("y", this.isFavorite(pID))
		.click(function(e){
      e.stopPropagation();
    })
    .tap(function(){
			_this.toggleFavorite(this,pID);
		});
		$(".back-to-top").click(function(e){
      e.stopPropagation();
    }).tap(function(){
			_this.scrollToElement("#");
		});
    VRA.logPageEvent("PublicWebsite.initAssetDetailPage END");
	};
	this.shortCutNav = function(shortCut){
	  var href = $(shortCut).attr("href");
    _this.scrollToElement(href,100,0,function(){
      _this.makeAssetDetailScrollResizeAdjustments();
      if(_this.shortCutBar.hasClass('travel'))
        _this.scrollToElement(href,100,-_this.shortCutBar.height());
    });
	};
	this.makeGlobalScrollAdjustments = function() {
		_this.windowTop = $(window).scrollTop();
		$("body").toggleClass("scrolled",_this.windowTop > 40);
	};
	this.makeAssetDetailScrollResizeAdjustments = function() {
	  if(_this.shortCutBar) {
  		var 
      contactFormGroupRect = verge.rectangle(_this.contactFormGroup),
  		headerHeight = _this.header.outerHeight(),
  		gallery = $(".gallery-preview").length ? $(".gallery-preview") : $("#gallery"),
  		shortCutsTraveling = verge.rectangle(gallery).bottom < _this.header.outerHeight() && contactFormGroupRect.top > 300;
  		_this.shortCutBar.toggleClass("travel",shortCutsTraveling);
  		_this.shortCutBar.css("top",headerHeight);
  		if(!_this.cssCalcIsSupported)
  			_this.shortCutBar.width(shortCutsTraveling ? _this.assetContentMain.outerWidth()+5 : "auto");
	  }
	};
	/**
	 * Initialize TN3 gallery on detail page.
	 */
	this.initMediaGalleryAssetDetail = function() {
    VRA.logPageEvent("PublicWebsite.initMediaGalleryAssetDetail BEGIN");
    var callback = function(status) {
      VRA.logPageEvent('PublicWebsite.initMediaGalleryAssetDetail END ('+status+')');
      _this.makeAssetDetailScrollResizeAdjustments();
      _this.loadLazy({
        threshold:9999
      });
      _this.loadLowPriorityStuff();
    };
		if($('.gallery').length && typeof VRA.photoGalleryData != undefined) {
		  // Set full urls in data:
		  var 
		  albumData = $.extend(true, {}, VRA.photoGalleryData[0]),
      galleryType = _this.whichMediaGallery();
		  if(VRA.toType(albumData.imgs)=='array' && albumData.imgs.length > 0) {
  		  albumData.imgs = albumData.imgs.map(function(imgData){
  		    if(typeof imgData.content == "string")
  		      return imgData;
  		    else
  		      return {
  		        title:imgData.title,
  		        img:albumData.imgDir+imgData.img,
  		        thumb:albumData.imgDir+imgData.thumb
  		      };
  		  });
  		  delete(albumData.imgDir);
        if(galleryType=='slippry')
          this.initSlippryGallery(albumData, callback);
        else
          this.initTN3Gallery(albumData, callback);
		  } else {
	      callback('bad or empty gallery data');
		  }
    } else {
      callback('no gallery data');
		}
	};
	this.initTN3Gallery = function(albumData, callback) {
    if(VRA.toType(VRA.tn3Config)=="object") {
      this.loadMediaGalleryResources('tn3').wait(function(){
        _this.tn3Gallery = $('.gallery').tn3({
          skinDir : VRA.tn3Config.mainDir+"skins",
          skin : VRA.tn3Config.skin,
          responsive:"width",
          mouseWheel:false,
          inactive:["albums","album","play"],
          image:{
            crop : true
          },
          thumbnailer:{
            speed:1,
            overMove:true
          },
          touch:{
            fsMode:"/tn3_touch_fullscreen.html"
          },
          init:function() {
            $('.gallery-preview').remove();
            if(typeof callback == "function")
              callback('TN3 initialized');
          }
        }).data("tn3");
        $('.gallery').show();
        _this.tn3Gallery.setAlbumData([albumData]);
      });
    }
  };
  this.resizeSlippryGallery = function() {
    $('#gallery').css('width',(verge.viewportW()<992 ? (Math.min(1/verge.aspect(),1)*100) : 100)+'%');
  };
  this.initSlippryGallery = function(albumData, callback) {
    VRA.logPageEvent('Initialize Slippry for mobile BEGIN');
    $('#gallery script').remove();
    var items = '';
    albumData.imgs.forEach(function(slide) {
      items += '<li>'+(slide.img ? '<img src="'+slide.img+'" alt="'+slide.title+'">' : slide.content)+'</li>';
    });
    $('#gallery').replaceWith('<ul id="gallery" class="gallery">'+items+'</ul>');
    this.loadMediaGalleryResources('slippry').wait(function(){
      _this.slippryGallery = $("#gallery").removeClass('gallery').slippry({
        transition: 'horizontal',
        auto:false,
        pager:false,
        hideOnEnd:false,
        onSliderLoad:function(){
          $('.gallery-preview').remove();
          $('#gallery').removeAttr('id');
          $('.sy-box').attr('id','gallery').on('movestart', function(e) {
            if ((e.distX > e.distY && e.distX < -e.distY) || (e.distX < e.distY && e.distX > -e.distY)) {
              e.preventDefault();
            }
          }).on('swipeleft',function(e){
            _this.slippryGallery.goToNextSlide();
          }).on('swiperight',function(e){
            _this.slippryGallery.goToPrevSlide();
          });
          if(typeof callback == "function")
            callback('slippry initialized');
        }
      });
      VRA.addWindowListener('resize',_this.resizeSlippryGallery);
    });
	};
	this.getFixedElementsHeight = function(result) {
		result = 0;
		$("header,.shortcuts").filter(":visible").each(function(){
			if($(this).css("position")=="fixed")
				result += $(this).outerHeight();
		});
		return result;
	};
	this.initSearchForm = function() {
		this.searchForm = $("#search-form");
		if(this.searchForm.length) {
			VRA.searchPropertyPublic = new SearchPropertyPublic();
			VRA.searchPropertyPublic.initForm();
		}
	};
  this.initSearchOpenButton = function() {
    $(".search-toggle").click(function(e){
      e.stopPropagation();
    }).tap(function(){
      _this.openSearch('fast');
      $(this).addClass('active');
    });
  };
  this.openSearch = function(time,callback) {
    var scrollFunc = function(){
      if(!_this.overGridBreakPoint())
        _this.scrollToElement(this,time,0,callback);
    };
    if($('.jumbotron').length) {
      $(".jumbotron").slideDown(time,function(){
        $(".jumbotron .search-panel-container").fadeIn("fast",scrollFunc);
      });
    } else {
      $("#search-form").slideDown(time,scrollFunc);
    }
  };
	this.initMaps = function() {
		this.mapGroup = $(".locale-map-group");
		if(this.mapGroup.length) {
			this.positionMap();
			VRA.addWindowListener("scroll",this.positionMap);
			VRA.addWindowListener("resize",this.positionMap);
      this.extCssCallbacks.add(this.positionMap);
		}
		VRA.mapManager.initMaps();
	};
	this.positionMap = function() {
		var 
		viewPortH = verge.viewportH(),
		listRect = verge.rectangle(_this.listingsGroup),
		fixedElementsHeight = _this.getFixedElementsHeight(),
		oldMapHeight = _this.mapGroup.height(),
		newMapHeight = viewPortH-Math.max(0,listRect.top);
		if(newMapHeight != oldMapHeight) {
	    _this.mapGroup.height(newMapHeight);
	    VRA.mapManager.redrawAll();
		}
		if(_this.windowTop == 0)
		  fixedElementsHeight += _this.searchForm.outerHeight();
		if(listRect.top < fixedElementsHeight && listRect.bottom > viewPortH) {
		  _this.mapGroup.css({
				"position":"fixed",
				"top":fixedElementsHeight+"px"
			});
		} else {
		  _this.mapGroup.css({
				"position":"relative",
				"top":0
			});
		}
	};
	this.initSimilarAssets = function() {
    $("#similar-assets").find("li").click(function(e) {
      _this.selectRental(e,this);
		});
	};
	this.getRateRangeStr = function(lo,hi,limitMin,limitMax) {
		rangeText = "$" + lo +  this.rangeSeparator + "$" + hi;
		if(hi == limitMax)
			rangeText += "+";
		return rangeText;
	};
	this.getBedroomsRangeStr = function(lo,hi,limitMin,limitMax) {
		var str = this.getBedroomStr(lo)+this.rangeSeparator+this.getBedroomStr(hi);
		if(hi == limitMax)
			str += "+";
		return str;
	};
	this.getBedroomStr = function(n) {
		return n==0 ? "Studio" : n;
	};
	this.getSleepsRangeStr = function(lo,hi,limitMin,limitMax) {
		return lo + this.rangeSeparator + hi + (hi == limitMax ? "+" : "");
	};
	this.initRentalSelection = function() {
		$("ul.listings-items > li").tap(function(e) {
			_this.selectRental(e,this);
		}).find("button.favorites").click(function(e){
      e.stopPropagation();
		}).tap(function(e) {
			_this.toggleFavorite(this,$(this).parents("li").first().data("pid"));
		});
		var favorites = this.getFavorites();
		favorites.forEach(function(id){
		  var favoritePod = $("li[data-pid$='"+id+"']");
		  if(favoritePod)
		    $(favoritePod).find("button.favorites").addClass("y");
		});
		$("ul.listings-items").find("button.favorites").css({
		  visibility:"visible",
		  opacity:1
		});
	};
	this.selectRental = function(event,pod) {
		var imgLink = $(pod).find("a").first();
		var url = $(imgLink).attr("href");
		if($(imgLink).prop("target") == "_blank") {
			event.preventDefault();
			window.open(url);
		} else {
			window.location.assign(url);
		}
	};
	this.getFavorites = function() {
	  return VRA.SUCM.get('favorites') || [];
	};
	this.isFavorite = function(propertyID) {
    var favorites = this.getFavorites();
    return favorites.indexOf(propertyID) > -1;
	};
	this.toggleFavorite = function(btn,propertyID) {
		var favorites = this.getFavorites();
		var index = favorites.indexOf(propertyID);
		var addingFavorite = index === -1;
    $(btn).toggleClass("y", addingFavorite);
		if(addingFavorite) {
      favorites.push(propertyID);
		} else {
      favorites.splice(index, 1);
		}
		VRA.SUCM.set('favorites', favorites,30);
    this.updateFavoritesCountDisplay();
		if (VRA.preHeader=="favorites" && !addingFavorite) {
			$(btn).css("visibility","hidden").parents("li").first().fadeOut("fast");
			$('nav.breadcrumbs > span').html("&nbsp;("+favorites.length+")");
		}
	};
	this.updateFavoritesCountDisplay = function() {
    var favorites = this.getFavorites();
    $('header .fave-btn a .badge,#tab-bar .favorites .badge')
        .text(favorites.length || '');
    // Set the URL for addthis tools to share on the favorites page:
    if(VRA.preHeader == "favorites") {
      window.addthis_share = {
        url : VRA.currServer+'favorites.htm',
        title : document.title
      };
      if(favorites.length)
        window.addthis_share.url += '?ids='+favorites.join();
    }
  };
	this.showVirtualTour = function(url) {
		window.$LAB.sandbox().script(VRA.dirWsJs+"jquery.colorbox-min.js").wait(function() {
			$.colorbox({
				href:url,
				iframe:true,
				fastIframe:true,
				width:"80%",
				height:"80%",
				fixed:true,
				overlayClose:true				
			});
		});
	};
	this.cssCalcSupported = function(prefix) {
	    prefix = prefix || '';
	    var el = document.createElement('div');
	    el.style.cssText = prefix + 'width: calc(1px);';
	    return !!el.style.length;
	};
	this.loadLazy = function(opt) {
		if(typeof opt != "object")
			opt = {threshold:0};
		$("img").unveil(opt.threshold,function() {
			$(this).prevAll(".loader").remove();
			_this.lazyLoadedImages++;
			if(typeof opt.initMapsAfter != "undefined" && _this.lazyLoadedImages >= opt.initMapsAfter) {
				_this.initMaps();
				delete opt.initMapsAfter;
			}
		});
	};
	this.scrollToElement = function(targ,time,vOffset,callback) {
		if(typeof time == "undefined")
			time = 0;
		if(typeof vOffset == "undefined")
			vOffset = 0;
		if(typeof $(targ) == "object" && typeof $(targ).offset() == "object") {
			$("body").toggleClass("scrolled",$(targ).offset().top>0);
			vOffset -= _this.getFixedElementsHeight();
			VRA.scrollToElement(targ,time,vOffset,callback);
		}
	};
	this.initContactForm = function() {
		VRA.publicContactForm = new PublicContactForm;
		VRA.publicContactForm.init();
	};
  /**
   * Check to see if there is a valid EID in the query string of the current URL.
   * Check all letter-case combinations of 'eid'.
   * Rules for EID pattern recognition:
   * Two patterns:
   * Pattern 1 example: 675.1.65730300.keyword={keyword}-adgroupid={adgroupid}-matchtype={matchtype}
   * - Always begins with 3 numbers of any length, each followed by a period.
   * - Keyword, adgroupid, matchtype always appear in that order.
   * - Allow only upper and lower case letters in the keyword and matchtype.
   * - Allow only digits in the adgroupid.
   * Pattern 2 example: 785.36.153.2015_1007
   */
  this.setEID = function() {
    var pattern = '^(\\d+\\.){3}(([a-zA-Z]+=[a-zA-Z0-9 ,\\-"\\{\\}\[\\]]+)(\\-([a-zA-Z]+=[a-zA-Z0-9 ,\\-"\\{\\}\\[\\]]+))*)|(\\d+_\\d+)$';
    var queryParams = document.location.search.replace(/(^\?)/,'').split("&").map(function(n){return n = [n.split("=")[0],n.substring(n.indexOf("=")+1)],this[n[0].toLowerCase()] = n[1],this}.bind({}))[0];
    var eid = queryParams.eid;
    if(eid && VRA.rgx(eid, pattern)) {
      // console.log('EID matches pattern.');
      VRA.SUCM.set('EID', eid);
    } else {
      // console.log('No matching EID');
    }
  };
  /**
   * Get page-wide phone number from origin server and replace default number with it.
   * CSS selector for all page-wide phone numbers is "a.phone.page"
   */
  this.setPagePhoneNumber = function(propertyId) {
    this.callPhp({
      cls:this.phpClass,
      mthd:"getSiteOrPropertySalesPhoneHtml",
      params:{
        pid:propertyId||0,
        cacheDuration:0
      },
      webCache: true,
      callback:function(phoneLink) {
        if(typeof phoneLink == 'string' && phoneLink.length>8)
          $('a.phone.page').replaceWith(phoneLink);
      }
    });
  };
  this.updateListingsPhoneNumbers = function() {
    var pids = [];
    $("#listings-group .listings-items > li").each(function(idx, pod){
      pids.push($(this).data("pid"));
    });
    if(pids.length)
      this.callPhp({
        cls:this.phpClass,
        mthd:"getPropertySalesPhoneNumbers",
        params:{
          pids:pids
        },
        webCache: true,
        callback:function(phoneLinks) {
          if(VRA.toType(phoneLinks)=="object")
            $.each(phoneLinks, function(pid, link){
              var sel = "#listings-group .listings-items > li[data-pid$='"+pid+"'] a.phone";
              $(sel).replaceWith(link);
              $(sel).click(function(e){
                e.stopPropagation();
              });
            });
        }
      });
  };
  this.mainStylesheetLoaded = function() {
    VRA.logPageEvent('PublicWebsite main stylesheet loaded');
    _this.extCssCallbacks.fire();
  };
  this.loadLowPriorityStuff = function() {
    VRA.logPageEvent('PublicWebsite.loadLowPriorityStuff');
    var lab = window.$LAB.sandbox();
    // Load Signal (BrightTag) on HBR only:
    if(VRA.preHeader != 'ra') {
      // If mobile, preload other css:
      if(!_this.overGridBreakPoint() && VRA.publicCssLoader)
        VRA.publicCssLoader.loadOtherMobileCss();
      // If not on rental or asset detail, preload the resources for them:
      if(VRA.preHeader != 'rental' && VRA.preHeader != 'asset')
        _this.loadMediaGalleryResources();
      lab.script('//s7.addthis.com/addthis_widget.js#pubid='+_this.addThisID).wait(function(){
        window.addthis.addEventListener('addthis.ready', function(){
          VRA.logPageEvent('AddThis ready');
          $('.favorites-sharing').animate({opacity:1},200);
        });
      });
    }
    if(VRA.site=='HBR')
      lab.script('//s.btstatic.com/tag.js#site=b0gFHut');
  };
  this.whichMediaGallery = function() {
    return verge.viewportW()<1025 ? 'slippry' : 'tn3';
  };
  this.loadMediaGalleryResources = function(type) {
    type = type || this.whichMediaGallery();
    VRA.logPageEvent('Load '+type+' gallery resources');
    if(type == 'slippry') {
      return window.$LAB.sandbox().script(VRA.dirWsJs+'bundles/combined-slippry-move-swipe.min.js');
    } else if(VRA.toType(VRA.tn3Config)=="object") {
      window.loadCSS(VRA.tn3Config.stylesheetUrl);
      return window.$LAB.sandbox().script(VRA.tn3Config.mainDir+'js/jquery.tn3.min.js');
    }
  };
}

PublicWebsite.prototype = new AppCtrl({aspSpecific:0});